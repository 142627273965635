// LAYOUT
//----------------------------------------------

// Container
//----------------------
%container {
  @include container;

  padding-right: 20px;
  padding-left: 20px;
}

// Wide Container
//----------------------
%wide {
  @include bleed-x(100%);
}

// Clearfix
//----------------------
%clearfix {}

// LISTS
//----------------------------------------------

// Lists Reset
//----------------------
%list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
}

// Inline Block
//----------------------
%inline-block {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  zoom: 1;
}

// IMAGES
//----------------------------------------------

// Image replacement
//----------------------
%replace-image {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
}

// ICONS
//----------------------------------------------

// Icon before static settings
//----------------------
%icon-before {
  &:before {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;

    vertical-align: baseline !important;
    font: {
      family: $icon;
      style: normal;
      weight: normal;
      variant: normal;
    };
    text: {
      decoration: inherit;
      transform: none;
    };
    speak: none;
    line-height: 1em;
    width: 1em;
  }
}

// Icon after static settings
//----------------------
%icon-after {
  &:after {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;

    vertical-align: baseline !important;
    font: {
      family: $icon;
      style: normal;
      weight: normal;
      variant: normal;
    };
    text: {
      decoration: inherit;
      transform: none;
    };
    speak: none;
    line-height: 1em;
    width: 1em;
  }
}

// BUTTONS
//----------------------------------------------

// Button Static Setings
//----------------------
%btn {
  display: block;
  margin: 20px 0;

  a {
    padding: 12px;
    color: #fff;

    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }
}

// Grayscale Effect
//----------------------------------------------
%grayscale {
  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: gray;
    -webkit-transition: all .6s ease;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");

    &:hover {
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%);
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
    }
  }
}
