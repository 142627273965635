.page-node-3397, .theme-categorias-y-secciones {
    
    .l-wrapper-main {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), #000);
        color: #fff;

        h1 {
            color: $color-accent;
            text-align: center
        }

        h2.block-title {
            color: $color-accent;
            margin-top: 20px;
            &:after {
                content: '';
                background: $color-accent;
                height: 4px;
                width: 30px;
                display: block;
            }
        }
    
        h2.block-title, .view-header {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
            padding-right: 0px;
            padding-left: 0px;
        }

        .addthis_toolbox.b-addthis {
            margin-bottom: -50px;
            a {
                color: #fff !important;
                &:hover {
                    color: $color-accent !important;
                }
            }
        }

        .block-views {
            background: rgba(255,255,255,0.05);
            padding: 3%;
            margin: 100px 0;
            border-radius: 5px;
        }
    }

    .slick--view--peliculas {
        .slide__caption {
            width: 100% !important;
            padding: 0px !important;
        }

        ul.slide__content {
            padding: 10px;
        }

        .slide__description {
            background-size: cover;
            background-position: bottom;
        }

        .container {
            height: 100%;
            background: rgba(0, 0, 0, 0.0);
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 100%;
            .imagen {
            flex: 1 1 100%;
            img {}
            }
            .content {
            border-top: 1px solid $color-accent;
            display: flex;
            align-items: center;
            justify-content: left;
            flex-wrap: wrap;
            padding: 0 10px 10px 10px;
            @include font-size(12px);
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
            flex: 1 1 100%;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), #000);
            .title {
                a {
                    color: $color-accent;
                    &:hover {
                        color: #fff;
                    }
                }
            }
            .tipo {
                @include font-size(6px);
                text-transform: uppercase;
                padding: 10px 10px 2px 10px;
                border-bottom: 1px solid $color-accent;
                margin: 0px -10px;
                letter-spacing: .5px;
                font-weight: 500;
                background: rgba(255, 255, 255, 0.03);
                width: 100% !important;
                text-align: right;
                flex: 1 1 100%;
                &.Formación {
                // background: #d3182b;
                }
                &.Evento {
                // background: #1aa0e0;
                }
            }
            .title,
            .paisano,
            .generoduracion,
            .categoria,
            .sede,
            .fecha {
                flex: 1 1 100%;
            }
            .ano,
            .pais,
            .genero,
            .duracion {
                display: inline-block;
            }
            .paisano,
            .generoduracion {
                a {
                &:last-of-type {
                    &:after {
                    content: " | ";
                    }
                }
                }
            }
            .title {
                text-transform: uppercase;
                font-weight: 700;
                @include font-size(18px);
                letter-spacing: 1px;
                font-family: $header-font-family;
                background: rgba(255, 255, 255, 0.01);
                margin-left: -10px;
                margin-right: -10px;
                text-align: left;
                padding: 14px 10px;
                a {
                //   color: #fff;
                &:hover {
                    // color: $color-accent;
                }
                }
                &:after {
                content: '';
                background: $color-accent;
                height: 4px;
                width: 30px;
                display: block;
                }
            }
            .paisano {}
            .generoduracion {
                a {
                @include font-size(12px);
                }
                span {
                &:after {
                    content: "'";
                }
                }
            }
            .categoria {
                // display: none;
            }
            .fechasede {}
            .sede {}
            .fecha {}
            .invitacion {
                color: lighten($youtube, 30%);
            }
            }
        }
    }

    .slick--view--peliculas--block-11 {
        @media (min-width: 475px){
            max-width: 50%;
        }
        @media (min-width: 980px){
            max-width: 32.5%;
        }
    }
}