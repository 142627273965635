//-----------------------------------------------------------------
//-----------------------------------------------------------------
// FIELDS
//-----------------------------------------------------------------
//-----------------------------------------------------------------

// Header
//----------------------------------------------
// h1.page-title {
//   border-bottom: 1px solid;
//   padding-bottom: 10px;
// }

// Imagen Principal
//----------------------------------------------
.field-name-field-img-encabezado,
.field-name-field-img-principal {
  a {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .view-mode-full & {
    margin-bottom: 20px;
  }
}

// .node-type-produccion .field-name-field-img-principal {
//   figure:after {
//     content: '';
//     border-top: 35px solid #fff;
//     border-right: 1000px solid transparent;
//     position: absolute;
//     top: -25px;
//     width: 100%;

//     @include breakpoint($s) {
//       border-top: 55px solid #fff;
//       border-right: 1500px solid transparent;
//     }

//     @include breakpoint($m) {
//       border-top: 65px solid #fff;
//       border-right: 2000px solid transparent;
//     }

//     @include breakpoint($l) {
//       border-top: 70px solid #fff;
//       border-right: 2500px solid transparent;
//     }

//     @include breakpoint($xl) {
//       border-top: 90px solid #fff;
//       border-right: 2500px solid transparent;
//     }
//   }
// }

.node-type-persona .field-name-field-img-principal {
  // max-width: 420px;
  margin: 0 auto;
}

// Paragraphs
//----------------------------------------------
.entity-paragraphs-item {
  margin: 40px 0;
}

// Iconos - Taxonomias Datos
//----------------------------------------------
.field-name-post-date,
.field-name-field-tax-etiquetas {
  @include font-size(14px);

  line-height: 1;
  margin: 10px 0;

  &:before {
    vertical-align: baseline;
    margin-right: 4px;
  }
}

.field-name-post-date {
  @include icon-before(date);
}

.field-name-field-tax-etiquetas {
  @include icon-before(tags);
}

.field-name-field-link-imdb {
  float: right;
  padding: 6px;
  border: 1px solid;
  border-radius: 4px;

  a {
    @include logo(logo-imdb, 50px, 20px, 0 0);

    background-size: 100%;
  }
}

// Documento
//----------------------------------------------
.field-name-field-file-document {
  margin: 20px auto;
  text-align: center;
}

// Mapas
//----------------------------------------------

.ip-geoloc-map {
  margin: 20px 0;
}

//-----------------------------------------------------------------
//-----------------------------------------------------------------
// CONTENIDO COMPLETO
//-----------------------------------------------------------------
//-----------------------------------------------------------------

// Iconos - Taxonomias Datos
//----------------------------------------------
.b-header {
  @include breakpoint($m) {
    .field-name-post-date,
    .field-name-field-tax-tipo-actividad {
      display: inline-block;
      vertical-align: middle;
      font-size: 80%;
      line-height: 1;
      margin: 0px 0px 20px 0px;
      padding: 0 10px 0 0;
    }
  }
}

// Video - Nodo Produccion
//----------------------------------------------

.page-node .field-name-field-video-teaser {
  margin: 30px 0;
  padding: 30px 0;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

//-----------------------------------------------------------------
//-----------------------------------------------------------------
// TEASER
//-----------------------------------------------------------------
//-----------------------------------------------------------------
.view-mode-teaser {
  @extend %ds-2col-fluid-3-9;

  background: none;
  margin: 60px 0;

  a {
    color: #000;

    &:hover {
      color: $color-accent;
    }
  }

  .group-right {
    padding: 0px 20px 10px;
    @media screen and (max-width: 479px) {
      padding: 10px 0px 10px;
    }
  }

  img {
    display: block;
  }

  h3 {
    margin: 0px;
  }

  .field-name-post-date {
    margin-top: 3px;
  }

  .field-name-body {
    @include font-size(14px);
  }

  .field-name-node-link {
    font-weight: 900;
    text-decoration: underline;

    @include font-size(14px);
  }
}
