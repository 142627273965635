/**
 * TYPOGRAPHY
 **********************************************/

// Global
//----------------------
body {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  color: $base-font-color;
}

// Headers
//----------------------
h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  font-style: normal;
  line-height: 1.2;
  text-transform: uppercase;
}

h1 {}

h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

// Body
//----------------------
p { }

b, strong, label {
  font-weight: bold;
}

.field-label, .label-above {
  font-weight: bold;
  margin: 1em 0 .5em 0;
}

small {
  font-size: 80%;
}

blockquote {
  quotes: none;
  margin: 0;
}

// Links
//----------------------
a:link, a:visited, a:active, li a.active {
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: $color-link;
  border: none;
  background-color: transparent;

  &:hover {
    color: $color-link-hover;
    outline: 0;
  }
}

// Element invisible
//----------------------
.element-invisible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
