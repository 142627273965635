.b-presentacion {

	h2.block-title {
		display: none;
	}

	#b-presentacion {
		text-align: left;
		display: flex;
		flex-wrap: wrap;

		h1 {
			font-size: 24px;
			margin: 0px;
			padding: 20px; 
			background: #000;
			color: #fff;
			flex: 70%;
			font-weight: 900;
		}

		h2 {
			font-size: 20px;
			margin: 0px;
			padding: 20px;
			background: #000;
			color: #fff;
			flex: 30%;
			// text-transform: lowercase;
			font-weight: 100;
		}
	}
}