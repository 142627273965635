//----------------------------------------------------------------------
// MEDIA
//----------------------------------------------------------------------

//----------------------------------------------------------------------
// IMAGES
//----------------------------------------------------------------------
img,
media {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

//----------------------------------------------
// CAPTION
//----------------------------------------------
.media-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  font-size: 80%;
  line-height: 1.1;
  color: #fff;
  background: rgba(#000, 0.5);

  a & {
    color: #fff;

    &:hover {
      color: $color-link-hover;
    }
  }
}

//----------------------------------------------
// FIGURE
//----------------------------------------------
figure {
  position: relative;
  margin: 0;
  padding: 0;

  img {
    display: block;
    width: 100%;
  }
}

figcaption {
  @extend .media-caption;
}

//----------------------------------------------------------------------
// FLEXSLIDER
//----------------------------------------------------------------------
.flexslider {
  margin: 0;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  // padding: 1%; //rev
  box-shadow: none;
  position: relative;
  z-index: 1;

  img {
    display: block;
  }
  .flex-control-thumbs {
    li {
      max-width: 10% !important;
    }
  }
  // DIRECTION ARROWS
  //--------------------------------------------
  .flex-direction-nav {
    a {
      .field-name-field-paragraphs-contenido & {
        top: 40%;
      }

      overflow: visible;
      text-shadow: none;
      color: #fff;

      &:hover {
        color: #fff;
      }

      &.flex-prev,
      &.flex-next {
        // display: block;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &:before {
          font-family: $icon;

          @include font-size(56px);

          background: transparent;
          line-height: 100%;
          width: 40px;
          text-align: center;
        }
      }

      &.flex-prev {
        left: 0;

        &:before {
          content: "\f104";
          color: $color-accent;
        }
      }

      &.flex-next {
        right: 0;

        &:before {
          content: "\f105";
          color: $color-accent;
        }
      }
    }
  }

  // PAGING CONTROLS
  //--------------------------------------------
  .flex-control-nav {
    // li {
    //   @extend %inline-block;
    //   margin: 0 6px;
    // }
  }

  // Paging Controls - Thumbnails
  //--------------------------------------------
  .flex-control-thumbs {
    bottom: 0;
    margin: 0;

    // li {
    //   @include gallery(3);
    //   margin-top: 10px;
    //   margin-bottom: 10px;
    //   @media (max-width: $m) {
    //     @include gallery(4);
    //   }
    //   @media (max-width: $s) {
    //     @include gallery(6);
    //   }
    // }
  }

  // Paging Controls - Dots
  //--------------------------------------------
  .flex-control-paging {
    // li a {
    //   width: 12px;
    //   height: 12px;
    //   border-radius: 100%;
    //   background: $color-link;
    //   box-shadow: none;
    //   &:hover {
    //     background: $color-link-hover;
    //   }
    //   &.flex-active {
    //     background: $color-link-hover;
    //   }
    // }
  }

  // CAPTION
  //--------------------------------------------
  .flex-caption {
    @extend .media-caption;
  }
}

// Paging Controls Carousel
//----------------------------------------------
#carousel-controls {
  li img {
    transition: all 250ms ease-in-out;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  a.flex-prev,
  a.flex-next {
    display: block;
  }

  img {
    padding: 1px 1px 0 0;
  }
}

//----------------------------------------------------------------------
// SLICK-SLIDER
//----------------------------------------------------------------------
// .slick-slider {
//   position: relative;

//   li {
//     padding: 1px 1px 0 0;
//   }
// }

// .slick-prev,
// .slick-next {
//   position: absolute;
//   top: 30%;

//   // top: 0px;
//   // bottom: 0px;
//   width: 40px;
//   margin: 0;
//   padding: 0;
//   font-size: 0;
//   line-height: 0;
//   cursor: pointer;
//   color: #fff;
//   border: none;
//   outline: none;
//   transition: all 250ms ease-in-out;
//   background: rgba($color-accent, 0.5);

//   &:hover {
//     background: rgba($color-accent, 0.75);
//   }

//   &:focus {
//     outline: none;
//   }

//   &:before {
//     @include font-size(56px);

//     font-family: $icon;
//     line-height: 100%;
//     opacity: 0.85;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }

//   &.slick-disabled:before {
//     opacity: 0.25;
//   }
// }

// .slick-prev {
//   left: 0px;

//   &:before {
//     content: "\f104";
//   }
// }

// .slick-next {
//   right: 0px;

//   &:before {
//     content: "\f105";
//   }
// }

// SLICK
//----------------------------------------------
// Prevent carousel overflow by using container width and spacing
.slick-wrapper {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

// Add spacing to individual thumbnails
.slick--display--thumbnail.slick--skin--asnavfor .slick__slide {
  margin-top: 24px;
  margin-bottom: 48px;
}

//----------------------------------------------------------------------
// VIDEOS
//----------------------------------------------------------------------
.video-embed-description {
  text-align: center;
}

//----------------------------------------------------------------------

.file-media-left,
.file-media-right {
  margin-top: 10px;
}

.file-media-left {
  float: left;
  margin-right: 10px;
}

.file-media-right {
  float: right;
  margin-left: 10px;
}
