// BACK TO TOP
//----------------------------------------------
.btt {
  text-align: center;

    a.backToTop {
        @include icon-before(up);

        @include font-size(13px);

        font-weight: 400;
        line-height: 1;
        z-index: 10;
        display: none;
        width: 60px;
        height: 60px;
        text-align: center;
        border-radius: 0%;
        background: rgba(0, 0, 0, 0.4);
        opacity: 1;
        color: #fff;
        border: 2px solid #fff;
        padding: 12px 2px 4px 2px;

        &:before {
            @include font-size(30px);
        }

        &:hover {
            opacity: 1;
            color: #000;
            background: $color-accent;
            border-color: $color-accent;
        }

        @media (max-width: $m) {
            // font-size: 0;
            // line-height: 0;
            bottom: 40px;
            width: 60px;
            height: 60px;

            &:before {
                line-height: 38px;
            }
        }
    }
}