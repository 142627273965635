.paragraphs-item-paragraph-faq {
  font-family: $header-font-family;
  font-weight: 700;

  .field-items {
    // display: flex;
    // flex-wrap: wrap;
    // columns: 2;

    .field-item {
      .ui-widget-content {
        border: none;
      }

      .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
        border: none;
      }

      .ui-accordion {
        display: flex;
        flex-wrap: wrap;
      }

      h3.faqfield-question {
        background: #fff;
        font-weight: 600;
        text-transform: none;
        padding-left: 1.5em;
        flex: 1 1 100%;

        @include font-size(17px);

        @include breakpoint($m) {
          flex: 1 1 50%;
        }


        .ui-accordion-header-icon {
          background: #000;
          border-radius: 100%;
          width: 6px;
          height: 6px;
          margin-top: -2px;
        }

        a {}

        &.ui-state-active {
          flex: 1 1 100%;
          margin-top: 50px;

          &:first-child {
            margin-top: 0px;
          }

          a {
            color: $color-accent;
          }
        }
      }

      .faqfield-answer {
        // height: auto !important;
        padding-top: 0px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        margin-bottom: 50px;
        flex: 1 1 100%;
        padding-left: 0px;
        margin-left: 0px;
      }

      p {
        @include font-size(15px);

        font-family: $header-font-family;
        font-weight: 700;

        // margin-top: 0px;
        // text-align: center;

      }

      span {
        // display: none;

      }
    }
  }
}
