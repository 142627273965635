// BLOQUE GANADORES
.page-node-1239 {

  h1.page-title {
    display: none;
  }
}

.view-ganadores-crfic-2015 {
  .view-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    h3 {
      flex: 1 1 100%;
      text-align: center;

      p {
        text-align: left;
      }
    }
  }

  .views-row {
    position: relative;
    height: 275px;
    margin-bottom: 20px;
    color: #fff;
    flex: 1 1 100%;

    @include breakpoint($s) {
      margin: 1%;
      flex: 1 1 48%;
    }


    @include breakpoint($m) {
      margin: 1%;
      flex: 1 1 31%;
    }


    // @include breakpoint($l) {
    //   margin: 1%;
    //   flex: 1 1 23%;
    // }


    a {
      color: #fff;

      &:hover {
        color: $color-accent;
      }
    }

    // CONTENT THEMING
    .container {
      height: 100%;
      background: rgba(0, 0, 0, 0.5);

      .content {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        padding: 10px;
        height: 100%;

        &:hover {
          .paisano, .categoria, .direccion {
            opacity: 1;
          }
        }

        .paisano, .categoria, .direccion {
          transition: all 0.3s;
          opacity: 0;
        }

        @include font-size(11.5px);

        .title, .paisano {
          flex: 1 1 100%;
        }

        .ano, .pais, .genero, .duracion {
          display: inline-block;
        }

        .paisano {
          text-align: center;

          a {
            &:last-of-type {
              &:after {
                content: " | ";
              }
            }
          }
        }

        .direccion {
          display: block;
          width: 100%;
          text-align: center;

          a {
            // color: $color-accent;

            &:hover {
              // color: #fff;
            }
          }
        }

        .title {
          text-transform: uppercase;
          font-weight: 600;
          text-align: center;

          @include font-size(20px);

          letter-spacing: 1px;
          // margin: -10px -10px 10px -10px;
          // background: #000;
          // padding: 10px;
          // border-bottom: 4px solid $color-accent;
        }

        .paisano {
          margin: 5px auto;
        }

        .categoria {
            display: block;
            width: 100%;
            text-align: center;
        }

        .premios {
          margin: 5px 0px;
          color: $color-accent;
          text-align: center;
          display: block;
          width: 100%;

          @include font-size(14px);
        }
      }
    }
  }
}
