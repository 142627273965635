.page-documentos {
	.l-main {
		padding-left: 0px;
		padding-right:  0px; 

		.l-content {
			max-width: 100%;
			width: 100%;
			padding-top: 0px;
			padding-bottom: 0px;

			h1.page-title {
				padding: 20px;
				text-align: center;
				margin-top: 0px;
				margin-bottom: 0px;
				background: #000;
				color: #fff;
			}
		}
	}
}