// // FRONT PAGE
// //----------------------------------------------
.l-wrapper-above-main, .l-wrapper-main {
  h2.block-title {
    font-family: $header-font-family;
    font-weight: 900;
    text-align: center;
    margin-top: 80px;

    @include font-size(28px);

    &:after {
      content: '';
      width: 50px;
      height: 8px;
      background: #000;
      display: block;
      margin: 10px auto 0px;
    }
  }
}
.front {
  .l-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}