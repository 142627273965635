.b-mosaico-documentos {
	// border-top: 4px solid darken($color-accent, 5);
	// border-bottom: 4px solid darken($color-accent, 5);
	// margin-bottom: -20px;

	.view-content {
	        display: flex;
	        align-items: center;
	        justify-content: flex-start;
	        flex-wrap: wrap;

		.views-row {
	            min-height: 250px;
	            flex: 1 1 100%;
	            background-size: cover;
	            display: flex;
	            align-self: stretch;
			
			&:hover .alpha-mosaico .container-mosaico h1 a {
				// @include font-size(40px);
				font-weight: 100 !important;
				opacity: 0.5;
			}

			&:nth-child(even) {
	            	.alpha-mosaico {
	            		background: rgba($color-accent, 0.9); 
          				
          				&:hover {
          					background: rgba($color-accent, 0.75); 
          				}
		            	
		            	.container-mosaico {
		            		color: #333;

		            		h1 {
		            			&:before {
		            				background: #000;
		            			}		            		

			            		a {
			            			text-transform: uppercase;
			            			font-weight: 900;
			            			color: #000;
			            		}
		            		}
		            		
		            		.cta {

			            		a {
			            			color: #000;
			            			border: 1px solid #000;

			            			&:hover {
		            					color: $color-accent;
		            					background: #000;
			            			}
			            		}
			            	}	
		            	}
	            	}
	            }

	            .alpha-mosaico {
	            	width: 100%;
	            	background: rgba(0,0,0,0.9);
	            	display: flex;
	            	align-self: stretch;
				transition: 0.3s all;
    				
    				&:hover {
    					background: rgba(0,0,0,0.75);
    				}

	            	.container-mosaico {
	            		margin: 2%;
	            		padding: 2%;
	            		align-self: stretch;
	            		flex: 1 1 100%;
	            		color: #fff;
	            		transition: 0.3s all;

	            		h1 {
	            			width: 100%;

	            			&:before {
	            				content: '';
	            				display: block;
	            				height: 4px;
	            				width: 100px;
	            				margin-bottom: 10px;
	            				background: $color-accent;
	            			}

	            			a {
	            				color: $color-accent;
	            				text-transform: uppercase;
	            				@include font-size(24px);
	            				line-height: 24px;
	            				font-weight: 900;
	            				// font-family: $header-font-family;

	            				&:hover {
	            					opacity: 1;
	            					font-weight: 900 !important;
	            				}
	            			}
	            		}

	            		p {
	            			font-weight: 100;
	            			width: 100%;
	            			@include font-size(12px);
	            		}

	            		.cta {
	            			margin-top: 10%;
	            			width: 100%;

	            			a {
	            				color: $color-accent;
	            				text-transform: uppercase;
	            				@include font-size(18px);
	            				line-height: 20px;
	            				font-weight: 900;
	            				border: 1px solid $color-accent;
	            				padding: 10px;
	            				display: inline-block;

	            				&:after {
	            					content: '\f105';
	            					font-family: $icon;
	            					margin-left: 3%;
	            					position: relative;
	            					top: -1px;
	            				}

	            				&:hover {
	            					color: #000;
	            					background: $color-accent;
	            				}
	            			}
	            		}	

	            	}
	            }

	            @include breakpoint($s) {
	                flex: 1 1 33%;
	            }
		}
	}
}