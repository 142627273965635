.b-convocatoria-2016-forms {
  @include span(full);

  clear: both;
  
  // Temporal
  margin: 0px 0 1-38px;
  // Fin temporal

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .form-link {
      @include btn($color-accent);
      width: 90%;
      margin: 10px 0px;
      align-self: center;

      a {
        display: block;

        // @include icon-after(download);

        @include font-size(16px);

        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;

        &:after {
          width: 0px;
          color: $color-accent;
          overflow: hidden;
          transition: 0.3s all;
          margin-bottom: -2px;
        }

        &:hover {
          &:after {
            width: 15px;
          }
        }
      }

      @include breakpoint(920px) {
        // @include gallery(15 of 15);
        width: 48%;
        padding:  0 1%;
        margin: 0px

        a {
          @include font-size(16px);
        }
      }
    }
  }
}
