.p-medios {
  .block-views {
    @include span(full);

    clear: both;
  }
}

.view-trailers,
.view-medios {
  .view-content {
    @include span(full);

    clear: both;
  }

  .views-row {
    margin-bottom: 20px;

    h3.title {
      @include font-size(15px);

      margin-top: 0;
    }

    @include breakpoint($m) {
      @include gallery(6);
    }


    @include breakpoint($l) {
      @include gallery(4);
    }
  }

  // .more-link {
  //   margin: 20px 0 80px;
  // }
}
