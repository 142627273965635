// FLUID TWO COLUMN LAYOUT - LEFT SPANS 2 / RIGHT SPANS 10
//----------------------------------------------
%ds-2col-fluid-3-9 {
  .group-left,
  .group-right {
    @include span(full);
  }

  .group-right {}

  @include breakpoint($s) {
    .group-left,
    .group-right {
      clear: none;
    }

    .group-left {
      @include span(3);
    }

    .group-right {
      @include span(last 9);
    }
  }


  &.group-one-column > {
    .group-left, .group-right {
      @include span(full);
    }
  }
}

// FLUID TWO COLUMN LAYOUT - LEFT SPANS 4 / RIGHT SPANS 8
//----------------------------------------------
%ds-2col-fluid-4-8 {
  .group-left,
  .group-right {
    @include span(full);
  }

  .group-right {}

  @include breakpoint($s) {
    .group-left,
    .group-right {
      clear: none;
    }

    .group-left {
      @include span(4);
    }

    .group-right {
      @include span(last 8);
    }
  }


  &.group-one-column > {
    .group-left, .group-right {
      @include span(full);
    }
  }
}

// FLUID TWO COLUMN LAYOUT - LEFT SPANS 6 / RIGHT SPANS 6
//----------------------------------------------
%ds-2col-fluid-6-6 {
  .group-left,
  .group-right {
    @include span(full);
  }

  .group-right {}

  @include breakpoint($s) {
    .group-left,
    .group-right {
      clear: none;
    }

    .group-left {
      @include span(6);
    }

    .group-right {
      @include span(last 6);
    }
  }


  &.group-one-column > {
    .group-left, .group-right {
      @include span(full);
    }
  }
}

// FLUID TWO COLUMN LAYOUT - LEFT SPANS 3 / RIGHT SPANS 9
//----------------------------------------------
%ds-2col-fluid-3-9 {
  .group-left,
  .group-right {
    @include span(full);
  }

  .group-right {}

  @include breakpoint($s) {
    .group-left,
    .group-right {
      clear: none;
    }

    .group-left {
      @include span(3);
    }

    .group-right {
      @include span(last 9);
    }
  }


  &.group-one-column > {
    .group-left, .group-right {
      @include span(full);
    }
  }
}
