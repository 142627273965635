.bases-link {
  @include btn($color-accent);
  width: 100%;
  margin: 0px 0px 30px;
  align-self: center;

  a {
    @include icon-after(download);

    @include font-size(16px);

    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;

    &:after {
      color: #000;
      overflow: hidden;
      transition: 0.3s all;
      margin-bottom: -2px;
      margin-left: 8px;
    }

    &:hover {
      color: #fff;
      &:after {
        color: #fff;
      }
    }
  }
} 
  
