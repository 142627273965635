.b-descarga-programas {
  @include span(full);

  clear: both;
  margin: 10px 0 50px;

  .descarga {
    @include btn($color-accent);

    a {
      display: block;

      // @include icon-after(download);

      @include font-size(14px);

      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;

      &:after {
        width: 0px;
        color: $color-accent;
        overflow: hidden;
        transition: 0.3s all;
        margin-bottom: -2px;
      }

      &:hover {
        &:after {
          width: 15px;
        }
      }
    }

    @include breakpoint(920px) {
      @include gallery(3 of 15);

      a {
        @include font-size(12px);
      }
    }
  }
}
