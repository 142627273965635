
.v-producciones-grid .tipo-Formación {
  .card_text {
    height: 100%;
    &:after {
      content: "Formación";
      position: absolute;
      top: 33%;
      left: 12px;
      @include font-size(18px);
      font-family: $header-font-family;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      color: #fff;
    }
  }
  .card_media {
    // position: relative;
    // &:after {
    //   content: "Formación";
    //   position: absolute;
    //   top: 80px; right: 20px;
    //   @include font-size(18px);
    //   font-family: $header-font-family;
    //   text-transform: uppercase;
    //   font-weight: 700;
    //   letter-spacing: 1px;
    //   color: #fff;
    // }
  }
}

//----------------------------------------------

.v-producciones-grid {

// view
//----------------------------------------------
  .view-content {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 1rem;
  }

// card
//----------------------------------------------
  .card {
    display: grid;
    position: relative;
    max-width: 450px;
  }

  .card:hover .card_text {
    height: 100%;
  }//.card:hover .card_text

// image
//----------------------------------------------
  .card_media {
    position: relative;
    &:before {
      @include bg-img(crficid, 50px);
      // @include bg-img(crficid-black, 50px);
      bottom: 10px; right: 20px;
      // top: 20px; left: 20px;

      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
    }
  }

// text
//----------------------------------------------
  .card_text {

    // layout
    //--------------------------
    display: flex;
    align-items: flex-end;
    // align-content: flex-end;
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    // style
    //--------------------------
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9)
    );
    color: #fff;
    @include font-size(12px);

    // behavior
    //--------------------------
    overflow: hidden;
    // transition: 0.5s ease;
    transition: all 250ms ease-in-out;

    // card_link
    //--------------------------
    .card_link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 99;
    }

    // card_description
    //--------------------------
    .card_description {
      padding: 1em;

      a {color: $color-accent;&:hover {color: #fff;}}
      .card_title {
        @include font-size(18px);
        font-family: $header-font-family;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        text-align: left;
        background: rgba(255, 255, 255, 0.01);

        margin-left: -10px;
        margin-right: -10px;
        padding: 14px 10px;

        &:after {
          content: "";
          background: $color-accent;
          height: 4px;
          width: 30px;
          display: block;
        }
      }//.card-title
    }//.card_description

  }//.card_text

  // Audiencias
  //------------------------------------------
  // .card_audiencia {text-align: right;}
  // Todo público
  .audiencia-372 .card_audiencia {color: $tp;}
  // Mayores de 12
  .audiencia-373 .card_audiencia {color: $m12;}
  // Mayores de 15
  .audiencia-374 .card_audiencia {color: $m15;}
  // Mayores de 18
  .audiencia-375 .card_audiencia {color: $m18;}

} // .view-peliculas-alt
