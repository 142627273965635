// Noticias
.view-edicion-noticias {
    .view-content {
        display: flex;
        flex-wrap: wrap;
        .views-row {
            flex: 1 1 100%;
            @include breakpoint($m) {
                flex: 1 1 50%;
            }
            @include breakpoint($l) {
                flex: 1 1 33%;
            }
            .view-mode-teaser {
                display: flex;
                flex-wrap: wrap;
                margin: 0px 0px 30px 0px;
                padding: 0px 2%;
                .group-left {
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    .field-name-field-img-principal {
                        margin-bottom: 0px;
                    }
                }
                .group-right {
                    width: 100%;
                    margin: 0px 0px 0px 0px ;
                    padding: 0px 0px 0px 0px;
                    background: $color-accent;
                    padding: 4%;
                    .field-name-title {
                        h3.title {
                            font-size: 16px;
                            font-weight: 900;
                            a {
                                &:hover {
                                    color: #fff;
                                }
                            }
                        }
                    }
                    .field-name-post-date {
                        background: #000;
                        color: #fff;
                        padding: 2%;
                        margin-top: 10px;
                        font-size: 12px;
                        font-weight: 100;
                        text-align: center;
                    }
                    .field-name-node-link {
                        text-align: center;
                        a {
                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Peliculas
.view-edicion-peliculas {
    .view-content {
        .views-row {
            height: auto !important;

            @include breakpoint($m) {
                flex: 1 1 31%;
                max-width: 31%;
            }

            .container {
                align-items: flex-start;
                .content {
                    flex: 1 1 100%;
                    background: black;
                    padding: 0px;
                    .photo {
                        flex: 1 1 100%;
                    }
                    .text {
                        padding: 10px;
                        .title {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

// Actividades
.view-edicion-actividades {
    .view-content {
        .views-row {
            height: auto !important;

            @include breakpoint($m) {
                flex: 1 1 31%;
                max-width: 31%;
            }

            .container {
                align-items: flex-start;
                .content {
                    flex: 1 1 100%;
                    background: $color-accent;
                    padding: 0px;
                    text-shadow: none;
                    .photo {
                        flex: 1 1 100%;
                    }
                    .text {
                        padding: 10px;
                        color: #000;
                        a {
                            color: #000 !important;
                            &:hover {
                                color: #fff !important;
                            }
                        }
                        .title {
                            display: block;
                            font-size: 16px;
                            font-family: $header-font-family;
                            font-weight: 900;
                            a{
                            }
                            &:after {
                                display: none;
                            }
                        }
                        .fechasede {
                            text-align: center;
                            .content {
                                .field-name-field-eref-sede {
                                    display: block;
                                    background: #000;
                                    margin: 10px 0;
                                    padding: 1% 2% !important;
                                    a {
                                        color: #fff !important;
                                        font-weight: 100 !important;
                                        font-size: 12px !important;
                                        &:hover {
                                            color: $color-accent !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// Jurados
.view-edicion-jurados {
    .view-content {
        .views-row {
            height: auto !important;

            @include breakpoint($m) {
                flex: 1 1 31%;
                max-width: 31%;
            }

            .container {
                align-items: flex-start;
                .content {
                    flex: 1 1 100%;
                    background: black;
                    padding: 0px;
                    .photo {
                        flex: 1 1 100%;
                    }
                    .text {
                        padding: 10px;
                        .title {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}


// Sedes
.view-edicion-sedes {
    .view-content {
        .views-row {
            height: auto !important;

            @include breakpoint($m) {
                flex: 1 1 31%;
                max-width: 31%;
            }

            .container {
                align-items: flex-start;
                .content {
                    flex: 1 1 100%;
                    background: $color-accent;
                    padding: 0px;
                    text-shadow: none;
                    .text {
                        padding: 10px;
                        color: #000;
                        a {
                            color: #000 !important;
                            &:hover {
                                color: #fff !important;
                            }
                        }
                        .title {
                            display: block;
                            font-size: 16px;
                            font-family: $header-font-family;
                            font-weight: 900;
                            a{
                            }
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


// EXPOSED FILTER - GENERAL VIEW
.view-edicion-peliculas,
.view-edicion-jurados {
    
    h2.title {
        padding: 1%;
    }

    .view-filters {
        border-bottom: 1px solid #000;
        margin: 1%;
        padding: 1% 0;
    }

    .views-exposed-widgets {
        display: flex;
        flex-wrap: wrap;
    }

    .views-exposed-widget {
        padding: 5px 0px;
        flex: 1 1 100%;

        @include breakpoint($m) {
            flex: 1 1 50%;
        }

        label {
            margin-bottom: 3px;
            text-transform: uppercase;
            @include font-size(14px);
        }

        .form-item-date-filter-value-date { 
            &:before {
                content: '\f073';
                font-family: $icon;
                background: #000;
                color: #fff200;
                padding: 6px 10px 7px;
                margin-right: -10px;
            }
        }

        select {
            border-radius: 0px;
            -webkit-appearance: none;
            border: 2px solid #000;
            padding: 3px 5px;
            text-transform: lowercase;
            margin-right: 1%;
            @include font-size(14px);
            background: #000;
            color: #fff;
            display: inline-block !important;
        }

        .chosen-container {
            display: none !important;
        }
    }

    // .views-exposed-form .views-exposed-widgets {
    //     // float: right;
    //     display: flex;
    //     flex-wrap: wrap;

    //     .views-exposed-widget {
    //         flex: 1 1 100%;
    //         margin-right: 10px;

    //         @include breakpoint($m) {
    //             flex: 1 1 48%;
    //         }


    //         .views-widget {
    //             text-align: center;
    //             display: flex;
    //             flex-wrap: wrap;
    //             padding: 5px 0px;
    //             flex: 1 1 100%;

    //             @include breakpoint($m) {
    //                 text-align: right;
    //                 flex: 1 1 50%;
    //             }


    //             .description {
    //                 display: none;
    //             }

    //             label {
    //                 margin-bottom: 3px;
    //                 text-transform: uppercase;

    //                 @include font-size(14px);
    //             }

    //             select {
    //                 border-radius: 0px;
    //                 -webkit-appearance: none;
    //                 border: 2px solid #000;
    //                 padding: 3px 5px;
    //                 text-transform: lowercase;

    //                 @include font-size(14px);

    //                 background: #fff;
    //                 color: #000;
    //                 width: 100%;
    //                 display: inline-block !important;
    //             }

    //             .chosen-container {
    //                 display: none !important;
    //             }
    //         }
    //     }
    // }

    .form-item-field-tax-tipo-actividad-tid {
        @extend %inline-block;

        .form-type-radio {
            position: relative;
            margin-right: 20px;
            margin-bottom: 10px;

            label.option {
                font-family: $header-font-family;
                font-weight: 700;
                text-transform: uppercase;

                @include font-size(14px);

                margin-left: 10px;
                cursor: pointer;

                &:after {
                    content: '';
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    margin-right: 5px;
                    background: transparent;
                    border: 2px solid #000;
                    opacity: 1;
                }

                &:hover::after {
                    opacity: 1;
                }
            }

            input[type=radio] {
                visibility: hidden;
                background: red;

                &:checked + label:after {
                    opacity: 1;
                    background: $color-accent;
                }
            }
        }
    }
}

// PELICULAS
.view-edicion-peliculas,
.view-edicion-jurados,
.view-edicion-actividades,
.view-edicion-sedes {
    .view-grouping-header {
        text-align: center;

        a {
            @include font-size(22px);

            font-weight: 900;

            &:before {
                content: '';
                background: $color-accent;
                height: 14px;
                width: 14px;
                border-radius: 100%;
                display: inline-block;
                margin-right: 5px;
            }
        }

        span {
            &:before, &:after {
                content: '';
                background: $color-accent;
                height: 14px;
                width: 14px;
                border-radius: 100%;
                display: inline-block;
                margin-right: 15px;
                margin-bottom: 8px;
            }

            &:after {
                margin-left: 15px;
                margin-right: 0px;
            }
        }
    }

    .view-grouping-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 60px;

        h3 {
            text-transform: none;
            flex: 1 1 100%;
            text-align: center;

            p {
                text-align: left;
            }
        }
    }

    .view-content {
        display: flex;
        align-items: top;
        justify-content: flex-start;
        flex-wrap: wrap;

        h3 {
            flex: 1 1 100%;
            text-align: left;

            p {
                text-align: left;
            }
        }
    }

    .views-row {
        position: relative;
        height: 400px;
        margin-bottom: 20px;
        color: #fff;
        flex: 1 1 100%;
        background-size: cover;

        @include breakpoint($s) {
            margin: 1%;
            flex: 1 1 100%;
        }


        @include breakpoint($m) {
            margin: 1%;
            flex: 1 1 48%;
        }

        // @include breakpoint($l) {
        //     margin: 1%;
        //     flex: 1 1 31%;
        // }

        a {
            color: $color-accent;

            &:hover {
                color: #fff;
            }
        }

        // CONTENT THEMING
        .container {
            height: 100%;
            background: rgba(0, 0, 0, 0);
            display: flex;
            align-items: flex-end;

            .tipo {
                @include font-size(12px);

                background: #000;
                text-transform: uppercase;
                padding: 10px;
                border-bottom: 4px solid $color-accent;
                letter-spacing: 0.5px;
                font-weight: 500;

                &.Formación {
                    background: #d3182b;
                }

                &.Evento {
                    background: #1aa0e0;
                }
            }

            .content {
                // height: 90%;
                display: flex;
                align-items: center;
                justify-content: left;
                flex-wrap: wrap;
                padding: 10px;
                flex: 100%;
                background: rgba(0,0,0,0.5);

                @include font-size(14px);

                text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);

                .title, .paisano, .generoduracion, .categoria, .sede, .fecha {
                    flex: 1 1 100%;
                }

                .ano, .pais, .genero, .duracion {
                    display: inline-block;
                }

                .paisano, .generoduracion {
                    a {
                        &:last-of-type {
                            &:after {
                                content: " | ";
                            }
                        }
                    }
                }

                .title {
                    text-transform: uppercase;
                    font-weight: 100;
                    margin: 0px;

                    @include font-size(34px);

                    letter-spacing: 1px;
                    font-family: $header-font-family;

                    &:after {
                        content: '';
                        background: #fff;
                        height: 8px;
                        width: 30px;
                        display: block;
                        margin-top: 0px;
                    }
                }

                .paisano {
                    // margin: 0px auto 40px;
                    // display: none;
               
                }

                .generoduracion {
                    // margin: 40px auto 0px;

                    a {
                        @include font-size(12px);
                    }

                    span {
                        &:after {
                            content: "'";
                        }
                    }
                }

                .categoria {
                    // display: none;
                    p {
                        margin: 0px;
                    }
                }

                .fechasede {
                    .content {
                        background: transparent;
                        padding: 0px;
                        .field {
                            display: block;
                            width: 100%;
                            .field-label {
                                margin: 0px 0px 0px 0px;
                                display: none;
                            }
                        }
                    }
                }

                .sede {
                    @include font-size(14px);

                    &:before {
                        content: '';
                        background: $color-accent;
                        height: 12px;
                        width: 12px;
                        border-radius: 100%;
                        display: block;
                        margin-bottom: 15px;
                    }
                }

                .fecha {
                    @include font-size(14px);
                }
            }
        }
    }
}

.view-edicion-peliculas,
.view-edicion-jurados {
    .view-grouping {
        margin: 40px 0;
    }

    .view-grouping-header .date-display-single {
        @include font-size(32px);

        font-family: $sans;
        text-transform: uppercase;
        font-weight: bold;
    }

    .title, .fecha {
        @extend %inline-block;
    }
}