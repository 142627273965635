.node-type-persona {
  .b-featured {
    margin-bottom: -25px;
    z-index: -1;

    @include breakpoint(768px) {
      margin-bottom: -40px;
    }


    .field-name-field-img-encabezado {
      text-align: center;
      max-height: 600px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .l-content {
    @include pad(20px);

    background-color: #fff;
    position: relative;
    z-index: 98;
    display: flex;
    flex-wrap: wrap;

    .l-highlighted {
      flex: 1 1 100%;
      order: 1;
    }

    .admin-tabs {
      flex: 1 1 100%;
      order: 2;
    }

    .field-name-field-img-principal {
      flex: 1 1 100%;
      order: 3;

      @include breakpoint($m) {
        flex: 1 1 40%;
        order: 4;
        padding: 18px 10px 10px 25px;
      }
    }

    .field-name-body {
      flex: 1 1 100%;
      order: 4;

      @include breakpoint($m) {
        flex: 1 1 60%;
        order: 3;
      }
    }

    .field-name-field-eref-producciones {
      flex: 1 1 100%;
      order: 5;
    }

    .view-articulos-relacionados-persona {
      flex: 1 1 100%;
      order: 6;

      .field-name-field-img-principal {
        padding: 0px;
      }
    }

    .field-name-addthis-sharing {
      flex: 1 1 100%;
      order: 7;
    }
  }
}

.view-articulos-relacionados-persona {
  h2.title {
    @include font-size(28px);

    font-family: $header-font-family;
    font-weight: 700;
    text-align: center;
    font-weight: 500;
    margin: 40px 0 20px 0;

    &:after {
      content: '';
      width: 50px;
      height: 8px;
      background: #000;
      display: block;
      margin: 10px auto 40px;
    }
  }
}
