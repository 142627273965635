.b-crfic-teaser {
  width: 100%;
  &__container {
    position: relative;
  }
  &__cover {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
