// Susy
//----------------------------------------------
@include border-box-sizing;

$susy: (
  columns: 12,
  container: 1340px,
  global-box-sizing: border-box
);

// Breakpoint
//----------------------------------------------

$xs: 320px;
$s:  480px;
$m:  768px;
$l:  960px;
$xl: 1020px;

// Color
//----------------------------------------------

// Social Network Colors
//----------------------
$facebook:  #3b5998;
$twitter:   #55acee;
$google:    #dd4b39;
$youtube:   #b00;
$vimeo:     #aad450;
$instagram: #517fa4;

// Theme Colors
//----------------------
$color-primary: #000;
$color-accent: #fbaacd;

// $color-accent: #3fbfad;
// $color-accent: #ee3d96;
// $color-accent: rgb(240,78,35);
// $color-accent: rgb(238,61,150);
// $color-accent: #782a90;
// $color-accent: #725fa1;
//
$color-primary-text:   #000;
$color-secondary-text: #aaa;
$color-divider:        #eee;

// Typography Colors
//----------------------
$base-font-color:  $color-primary-text;
$color-link:       #000;
$color-link-hover: $color-accent;

// Audiencias
//----------------------
// Todo público
$tp:  #3bbfad;
// Mayores de 12
$m12: #fcc21c;
// Mayores de 15
$m15: #ec6a44;
// Mayores de 18
$m18: #e72d87;


// Typography
//----------------------------------------------
@import "fonts";
$base-font-family:   $worksans !default;
$base-font-size:     16px;
$base-font-weight:   normal;
$base-line-height:   24px;
$header-font-family: $worksans !default;
$header-font-weight: 600;

// Icons
//----------------------------------------------
$icons: (
  home:      "\f015",
  menu:      "\f0c9",
  search:    "\f002",
  rss:       "\f09e",
  mail:      "\f0e0",
  phone:     "\f095",
  fax:       "\f1ac",
  marker:    "\f041",
  user:      "\f007",
  tags:      "\f02c",
  link:      "\f08e",
  date:      "\f073",
  time:      "\f017",
  camera:    "\f030",
  video:     "\f16a",
  attach:    "\f0c6",
  download:  "\f019",
  share:     "\f1e0",
  comment:   "\f075",
  left:      "\f104",
  right:     "\f105",
  up:        "\f106",
  down:      "\f107",
  facebook:  "\f09a",
  twitter:   "\f099",
  google:    "\f0d5",
  youtube:   "\f167",
  vimeo:     "\f27d",
  instagram: "\f16d",
  imdb:      "\e800",
  logo-img:  "\e801"
);
