a.cta-categoria {
  text-transform: uppercase;
  color: $color-accent;
  border: solid $color-accent;
  padding: 5px 5px 5px 5px;
  border-width: 0px 0px 1px 1px;
  font-size: 15px;
}

.p-acerca_categorias {
  margin-bottom: 40px;
}

a.btn {
  background-color: $color-accent;
  font-size: 13px;
  padding: 4px 8px;
  &:hover {
    background-color: darken($color-accent, 10%);
    color: $base-font-color;
  }
}

.b-personas-equipo {
  margin-right: -100%;
  margin-left: -100%;
  padding-right: 100%;
  padding-left: 100%;

  // margin-top: 2em;
  // margin-bottom: 2em;
  padding-top: 2em;
  padding-bottom: 2em;

  color: #fff;
  background-color: #161616;

  a {
    color: $color-accent;
    &:hover {
      color: darken($color-accent, 10%);
    }
  }
  // .ds-2col-fluid.node-persona.node-teaser {
  //   display: flex;
  //   flex-direction: column;
  //   color: red;
  // }
}

.view-slideshow-banners {
  // figure:after {
  //   content: '';
  //   border-top: 35px solid #fff;
  //   border-right: 1000px solid transparent;
  //   position: absolute;
  //   top: -25px;
  //   width: 100%;
  //   @include breakpoint($s) {
  //     border-top: 55px solid #fff;
  //     border-right: 1500px solid transparent;
  //   }
  //   @include breakpoint($m) {
  //     border-top: 65px solid #fff;
  //     border-right: 2000px solid transparent;
  //   }
  //   @include breakpoint($l) {
  //     border-top: 70px solid #fff;
  //     border-right: 2500px solid transparent;
  //   }
  //   @include breakpoint($xl) {
  //     border-top: 90px solid #fff;
  //     border-right: 2500px solid transparent;
  //   }
  // }
  // figcaption {
  //   @include font-size(32px);
  //   top: 50%;
  //   background: none;
  //   text-align: center;
  // }
}

.more-link,
.pager-show-more {
  @include span(12 of 12 0.25 inside fluid);
  text-align: center;
  margin: 0px auto;
  .view-blog & {
    margin-top: 20px;
    margin-bottom: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid #000;
  }
  a {
    font-family: $header-font-family;
    font-weight: 700;
    @include font-size(18px);
    padding: 10px 20px 5px;
    border: 2px solid #000;
    border-radius: 0px;
    text-transform: uppercase;
    display: inline-block;

    .dark & {
      border: 2px solid #fff;
      color: #fff;
    }
    &:hover {
      background: $color-accent;
      border-color: $color-accent;
      color: #000;
    }
  }
}

.pager {
  text-align: center;
  a {
    color: $color-accent;
    &:hover {
      color: #fff;
    }
  }
}

.element-invisible {
  width: 1px;
}
