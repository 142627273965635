/**
 * PAGERS
 **********************************************/
.item-list .pager {
  clear: both;
  // text-align: right;
}

.item-list ul.pager {
  margin: 24px 0;
  padding-top: 20px;
  border-top: 1px solid $color-divider;

  li {
    @extend %list-reset;

    @extend %inline-block;

    font-size: 90%;
    padding: 0 8px 0 0;
  }
}

.pager-current {
  font-weight: bold;
  color: $color-accent;
}
