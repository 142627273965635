// .v-personas-jurados, .v-personas-invitados,
.view-personas {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .views-row {
      flex: 1 1 100%;

      h2.title {
        @include font-size(20px);
        margin-top: 0px;
      }

      .field-name-field-tax-pais {
        @include font-size(14px);
      }

      @include breakpoint($s) {
        h2.title {
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .group-right {
          padding: 0 40px 10px;
        }
      }

      @include breakpoint($m) {
        flex: 1 1 50%;
        max-width: 50%;

        h2.title {
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .group-right {
          padding: 0 40px 10px;
        }
      }
    }

    // Slick
    ul.slide__content {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), #000);
      color: #fff;

      a {
        color: $color-accent;
        &:hover {
          color: #fff;
        }
      }

      .view-mode-teaser {
        margin: 30px 0px;
        h2.title {
          @include font-size(20px);
          margin-top: 0px;
        }
        .field-name-field-img-principal {
          a {
            img {
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}
