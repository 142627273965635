// CONTACT PAGE
//----------------------------------------------
.page-contact {
  .l-content {
    @include breakpoint($l) {
      .b-contact-info {
        @include span(5);
      }

      form.contact-form {
        @include span(last 7);
      }
    }
  }
}
