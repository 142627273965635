.node-type-produccion {
  // GENERAL
  .field-label,
  .label-above {
    text-transform: uppercase;
  }

  // HEADER
  section.b-header {
    .field-label,
    .label-above,
    .field-items {
      display: inline-block;
      margin: 2px 0px;
    }

    .field-name-field-link-imdb {
      margin-top: 10px;
      margin-bottom: -20px;
    }

    .field-name-field-date-lanzamiento {
      float: left;
      clear: right;
    }

    .field-name-title {
      float: left;
      clear: left;

      h1.page-title {
        margin-top: 5px;
      }
    }

    .field-name-field-tax-pais {
      clear: left;
    }

    .field-name-field-tax-pais,
    .field-name-field-eref-persona-direccion,
    .field-name-field-hms-duracion,
    .field-name-field-tax-genero,
    .field-name-field-tax-temas,
    .field-name-field-tax-categoria,
    .field-name-field-tax-tematicas,
    .field-name-field-tax-idioma {
      @include breakpoint($s) {
        @include span(6 of 12 0 inside fluid);
      }

      // @include breakpoint($m) {
      // 	@include span(4 of 12 0 inside fluid);
      // }
    }

    .field-name-field-hms-duracion {
      span.hms {
        &:after {
          content: "'";
        }
      }
    }

    .field-name-field-tax-categoria {
      margin-bottom: 40px;
    }

    .field-name-field-text-sinopsis {
      clear: left;
      margin-bottom: 10px;

      .label-above {
        font-weight: 300;
        text-decoration: underline;
      }
    }
  }

  nav.admin-tabs {
    clear: both;
  }

  // FICHA
  section.group-ficha {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin: 30px auto;
    padding: 30px 0px 50px;
    clear: both;

    .group-izquierda,
    .group-centro,
    .group-derecha {
      flex: 1 1 100%;
      text-align: left;

      @include breakpoint($s) {
        flex: 1 1 50%;
        text-align: left;
      }

      @include breakpoint($m) {
        flex: 1 1 33.333%;
      }
    }
  }

  // PERSENTACIONES
  .field-collection-container {
    border: none;
    clear: both;
  }

  .field-name-field-collection-actividades {
    .label-above {
      background: #000;
      padding: 10px;
      color: #fff;
    }

    .field-collection-view {
      margin: 0px;
      border: none;

      // .field-collection-item-field-collection-actividades {

      .field-label {
        font-weight: 100;
        @include font-size(12px);
        display: none;
      }

      .field-name-field-eref-sede {
        a {
          // text-transform: uppercase;
          font-weight: 500;
          @include font-size(20px);
          color: #000;

          &:hover {
            color: $color-accent;
          }

          &:before {
            content: "";
            background: $color-accent;
            height: 14px;
            width: 4px;
            display: inline-block;
            margin-right: 1%;
          }
        }
      }

      .field-name-field-date-actividad {
        span.date-display-single {
          text-transform: uppercase;
          font-weight: 500;
          @include font-size(14px);

          &:after {
            content: "";
            background: $color-accent;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            display: inline-block;
            margin-left: 1%;
            margin-bottom: -1px;
          }
        }
      }

      .field-name-field-link-boleteria {
        a {
          background: $color-accent;
          color: #000;
          padding: 8px 12px;
          text-transform: uppercase;
          // font-weight: 900;
          @include font-size(14px);

          &:before {
            content: "\f02c";
            margin-right: 5px;
            font-family: $icon;
          }

          &:hover {
            background: #000;
            color: $color-accent;
          }
        }
      }
      // }
    }
  }
}
