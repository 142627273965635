// THEMES DEMO
//----------------------------------------------
body.admin-menu.page-admin-structure-block-demo {
  margin-top: 0 !important;
}

.page-admin-structure-block-demo {
  #admin-menu {
    display: none;
  }

  a.block-demo-backlink {
    background-color: transparent;
    top: 60px;
    left: 0;
  }

  .block-region {
    margin: 0;
    opacity: .8;
  }
}
