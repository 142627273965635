// CONTACT INFO
//----------------------------------------------
.b-contact-info {
  h3 {
    margin-bottom: 0;
  }
  .contact-email,
  .contact-phone,
  .contact-fax,
  .contact-address {
    &:before {
      margin-right: 8px;
    }
  }
  .contact-email {
    @include icon-before(mail);
  }
  .contact-phone {
    @include icon-before(phone);
  }
  .contact-fax {
    @include icon-before(fax);
  }
  .contact-address {
    @include icon-before(marker);
  }
}
