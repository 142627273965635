.l-sidebar-first {
	display: flex;
	flex-wrap: wrap;

	// margin-top: -0.6%;//revisar
	    flex: 20%;
	    // width: auto;
	    flex-direction: column;
	    flex-wrap: wrap;
	    justify-content: flex-start;

	 .rutas-filtros-titulo {
	 	h1 {
	 		@include font-size(30px);
	 	}
	 }

	.block-facetapi {
		// flex: 1 1 100%;
		width: 100%;

		.block-title {
			background: #000;
			color: #fff;
			transition: 0.3s all;
			padding: 5px 10px;
			@include font-size(20px);
			font-weight: 900;
			cursor: pointer;
			margin: 1%;

			&.open {
				color: $color-accent;
			}

			&:after {
				display: none;
			}

			&:hover {
				color: $color-accent;
			}
		}

		.block-content {
			.item-list {
				display: none;
				&.open {
					display: block;
				}
				ul {
					li {
						list-style: none;
						margin: 3% 1%;
						@include font-size(12px);

						a {
							@include font-size(12px);

							&.facetapi-active {
								background: $youtube;
								color: #fff;
								font-weight: 900;
								padding: 4px 8px;
								margin-right: 5px;
								display: inline-block;

								&:hover {
									color: $color-accent;
									background: #000;
								}
							}
						}
					}
				}
			}
		}
	}
}

.page-apps {

	.l-main {
		display: flex;
		flex-wrap: wrap;

		.l-content {
			order: 2;
		}

		.l-sidebar-first {
			order: 1;
			flex: 20%;
		}
	}
}
