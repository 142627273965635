// ADDTHIS
//----------------------------------------------
.b-addthis {
  text-align: center;
  margin: 24px 0;

  a {
    @extend %inline-block;
  }

  span {
    font-size: 0;
    visibility: hidden;
    line-height: 0;
  }

  #facebook,
  #twitter,
  #google,
  #email {
    @include size(32px);

    @include font-size(20px);

    line-height: 36px;
    color: #000;
  }

  #facebook {
    @include icon-before(facebook);

    &:hover {
      color: $facebook;
    }
  }

  #twitter {
    @include icon-before(twitter);

    &:hover {
      color: $twitter;
    }
  }

  #google {
    @include icon-before(google);

    &:hover {
      color: $google;
    }
  }

  #email {
    @include icon-before(mail);

    &:hover {
      color: $color-accent;
    }
  }
}
