.node-type-edicion {
    main {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-right: 0px;
        padding-left: 0px;

        .l-content {
        	max-width: 100%;
        	padding-top: 0px;
        	padding-bottom: 0px;

        	.page-title {
        		text-transform: uppercase;
        		text-align: center;
        		color: $color-accent;
        		background: #000;
        		padding: 10px;
        		font-size: 38px;
        		margin: 0px;
        		font-weight: 900;
        		margin-top: -4px;
        	}

        	.view {
        		max-width: 1100px;
        		margin: auto;
        		padding: 10px;
        		padding-bottom: 180px;
                        clear: both;

        		h2.title {
        			text-align: center;
        			display: block;
        			margin-top: 0px;
        			padding: 10px;
        			background: #000;
        			color: $color-accent;
        		}

        		// .view-content {
	        	// 	max-width: 960px;
	        	// 	margin: auto;
        		// }
                // .more-link, .pager-show-more {
                //     @include span(12 of 12 0.25 inside fluid);

                //     text-align: center;
                //     margin: 0px auto 30px;

                //     a {
				// 		font-family: $header-font-family;
				// 		font-weight: 700;

                //         @include font-size(18px);

                //         padding: 5px 20px;
                //         border: 2px solid #000;
                //         border-radius: 0px;
                //         text-transform: uppercase;

                //         &:hover {
                //             background: $color-accent;
                //             border-color: $color-accent;
                //             color: #fff;
                //         }
                //     }
                // }
        	}
        }
    }
}