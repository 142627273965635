// Header (Site name / Logo)
//----------------------
.l-branding {
  overflow: hidden;
  h1.site-name {
    margin: 0;

    a {
      background-size: 100%;
      text-indent: 1000%;
      // visibility: hidden;

      @include logo(logo-general, 200px, 80px, 50%, contain);
      margin: 10% auto;

      @include breakpoint(0px 700px) {
        margin: 1% auto;
        @include logo(logo-general, 120px, 120px, 50%, contain);
      }

      .theme-crfic2015 & {
        @include logo(logo-2015, 190px, 100px, 0 5px);
      }

      .theme-crfic2016 & {
        @include logo(logo-2016, 80px, 80px, 50%, contain);
      }

      .theme-crfic2017 & {
        @include logo(logo-2017, 200px, 80px, 50%, contain);
      }

      .theme-crfic7 & {
        @include logo(logo-2018, 200px, 80px, 50%, contain);
      }

      .front & {
        // @include logo(logo-2019-blanco, 300px, 560px, center);
        @include logo(logo-general-white, 300px, 560px, center);
        margin: 0 auto;
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 160px 160px rgba(0, 0, 0, 0.2);

        @include breakpoint(0px 380px) {
          width: 145px !important;
          height: 300px !important;
          background-position: 0 20px !important;
        }

        @include breakpoint(0px 768px) {
          width: 140px;
          height: 430px;
        }
      }
    }
  }
}

.b-logo-secundario {
  float: right;
  clear: both;

  a.logo-secundario {
    @include logo(slogan-2016, 80px, 80px, 50%, contain);
    margin: 10% 0;

    .theme-crfic2015 & {
      @include logo(logo-secundario-2015, 143px, 100px, 0 5px);
    }
  }

  // @media (max-width: 580px) {
  //   display: none;
  // }
}

// @include breakpoint(0px 380px) {
//   .l-branding {
//     width: 100%;

//     a {
//       margin: auto;
//       background-position: center !important;
//       // height: 180px !important;
//     }
//   }

//   .l-header-region {
//     width: 100%;
//     margin-top: 0px !important;
//     display: none;

//     .b-logo-secundario {
//       float: none;

//       a.logo-secundario {
//         margin: auto;
//       }
//     }
//   }
// } // COMENTADO PARA CONSERVAR l-header-region

@include breakpoint(0px 700px) {
  .l-branding {
    margin-top: 0px;
    width: 100%;

    a {
      margin: auto;
      background-position: center !important;
      // height: 180px !important;
    }

    .front & {
      margin-top: 0px;
    }
  }

  .l-header-region {
    // margin-top: 180px;
    width: 100%;
    // display: none; // REV

    .b-logo-secundario {
      float: none;

      a.logo-secundario {
        margin: auto;
      }
    }

    .front & {
      margin-top: 0px;
    }
  }
}

.branding-global {
  flex: 1 1 100%;
  padding: 20px 0px;
  .block-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }

    a {
      text-align: center;
      opacity: 0.7;
      padding: 0 10px;
      @media screen and (max-width: 768px) {
      }

      &.crfic {
        opacity: 1;
        @media screen and (max-width: 414px) {
          flex: 1 1 100%;
          margin-top: 20px;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
