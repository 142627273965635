.view-categorias {

	.view-grouping-content {

		h3 {
			@include font-size(14px);
			text-transform: none;
			font-weight: 100;
			line-height: 1.5;
		}
	}
}