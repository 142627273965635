//----------------------------------------------
// Native font stack
//----------------------------------------------
$native-font-stack:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue",
  Arial,
  // Linux
  "Noto Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji"
!default;

//----------------------------------------------
// Custom fonts
//----------------------------------------------

@font-face {
  font-family: 'fontello';
  src: url(../fonts/fontello/font/fontello.eot);
  src: url(../fonts/fontello/font/fontello.eot#iefix) format("embedded-opentype"), url(../fonts/fontello/font/fontello.woff) format("woff"), url(../fonts/fontello/font/fontello.ttf) format("truetype"), url(../fonts/fontello/font/fontello.svg#fontello) format("svg");
  font-weight: normal;
  font-style: normal;
}

// Knockout
//------------------------------
// @font-face {
//   font-family: 'Knockout';
//   src: url(../fonts/knockout/Knockout-HTF28-JuniorFeatherwt.eot);
//   src: url(../fonts/knockout/Knockout-HTF28-JuniorFeatherwt.eot?#iefix) format("embedded-opentype"), url(../fonts/knockout/Knockout-HTF28-JuniorFeatherwt.woff) format("woff"), url(../fonts/knockout/Knockout-HTF28-JuniorFeatherwt.ttf) format("truetype");
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Knockout';
//   src: url(../fonts/knockout/Knockout-HTF90-UltmtWelterwt.eot);
//   src: url(../fonts/knockout/Knockout-HTF90-UltmtWelterwt.eot?#iefix) format("embedded-opentype"), url(../fonts/knockout/Knockout-HTF90-UltmtWelterwt.woff) format("woff"), url(../fonts/knockout/Knockout-HTF90-UltmtWelterwt.ttf) format("truetype");
//   font-weight: 900;
//   font-style: normal;
// }

// $knockout: "Knockout", "Arial Black", "Arial Bold", Gadget, sans-serif;

// Consolas
//------------------------------
// @font-face {
//   font-family: 'Consolas';
//   src: url(../fonts/consolas/Consolas.eot);
//   src: url(../fonts/consolas/Consolas.eot?#iefix) format("embedded-opentype"), url(../fonts/consolas/Consolas.woff) format("woff"), url(../fonts/consolas/Consolas.ttf) format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Consolas';
//   src: url(../fonts/consolas/Consolas-Italic.eot);
//   src: url(../fonts/consolas/Consolas-Italic.eot?#iefix) format("embedded-opentype"), url(../fonts/consolas/Consolas-Italic.woff) format("woff"), url(../fonts/consolas/Consolas-Italic.ttf) format("truetype");
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Consolas';
//   src: url(../fonts/consolas/Consolas-Bold.eot);
//   src: url(../fonts/consolas/Consolas-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/consolas/Consolas-Bold.woff) format("woff"), url(../fonts/consolas/Consolas-Bold.ttf) format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Consolas';
//   src: url(../fonts/consolas/Consolas-BoldItalic.eot);
//   src: url(../fonts/consolas/Consolas-BoldItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/consolas/Consolas-BoldItalic.woff) format("woff"), url(../fonts/consolas/Consolas-BoldItalic.ttf) format("truetype");
//   font-weight: bold;
//   font-style: italic;
// }

// $consolas: "Consolas", monaco, monospace;

// Oswald, Reemplaza a Knockout
//------------------------------
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
$oswald: "Oswald", Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", "Arial Bold", Gadget, sans-serif;

// Inconsolata, Reemplaza a Consolas
//------------------------------
@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700);
$inconsolata: "Inconsolata", Consolas, "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monaco, monospace;

// Roboto Mono
//------------------------------
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700);
$robotomono: "Roboto Mono",  sans-serif, monospace;

// Sugo Regular
//------------------------------
@font-face {
  font-family: 'Sugo Regular';
  src: url(../fonts/sugo/Sugo.eot);
  src: url(../fonts/sugo/Sugo.eot) format("embedded-opentype"), url(../fonts/sugo/Sugo.woff) format("woff"), url(../fonts/sugo/Sugo.ttf) format("truetype"), url(../fonts/sugo/Sugo.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
$sugoregular: "Sugo Regular";

// Cardo
@font-face {
  font-family: 'cardobold';
  src: url('../fonts/cardo/cardo-bold-webfont.woff2') format('woff2'),
       url('../fonts/cardo/cardo-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
$cardobold: "cardobold";

@font-face {
  font-family: 'cardoitalic';
  src: url('../fonts/cardo/cardo-italic-webfont.woff2') format('woff2'),
       url('../fonts/cardo/cardo-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
$cardoitalic: "cardoitalic";

@font-face {
  font-family: 'cardoregular';
  src: url('../fonts/cardo/cardo-regular-webfont.woff2') format('woff2'),
       url('../fonts/cardo/cardo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
$cardoregular: "cardoregular";

// Uniform
//------------------------------
// 1. Light
// 2. Regular
// 3. Medium
// 4. Bold
// 5. Black
// 6. Ultra
// Usage

// 1. Light
@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Light.otf);
  font-weight: 100;
  font-style: normal;
}
// 2. Regular
@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Regular.otf);
  font-weight: 300;
  font-style: normal;
}
// 3. Medium
@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Medium.otf);
  font-weight: 600;
  font-style: normal;
}
// 4. Bold
@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Bold.otf);
  font-weight: 700;
  font-style: normal;
}
// 5. Black
@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Black.otf);
  font-weight: 800;
  font-style: normal;
}
// 6. Ultra
@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Ultra.otf);
  font-weight: 900;
  font-style: normal;
}
// Uniform Webfont
$uniform: 'uniform',helvetica,arial,sans-serif;

// UniformCondensed
//------------------------------

// 1. Light
// 2. Regular
// 3. Medium
// 4. Bold
// 5. Black
// 6. Ultra
// Usage

// 1. Light
@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Light.otf);
  font-weight: 100;
  font-style: normal;
}

// 2. Regular
@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Regular.otf);
  font-weight: 300;
  font-style: normal;
}

// 3. Medium
@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Medium.otf);
  font-weight: 600;
  font-style: normal;
}

// 4. Bold
@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

// 5. Black
@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Black.otf);
  font-weight: 800;
  font-style: normal;
}

// 6. Ultra
@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Ultra.otf);
  font-weight: 900;
  font-style: italic;
}

// Uniform Webfont
$uniformCondensed: 'uniformCondensed',helvetica,arial,sans-serif;

// UniformExtraCondensed
//------------------------------

// 1. Light
// 2. Regular
// 3. Medium
// 4. Bold
// 5. Black
// 6. Ultra
// Usage

// 1. Light
@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Light.otf);
  font-weight: 100;
  font-style: normal;
}

// 2. Regular
@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Regular.otf);
  font-weight: 300;
  font-style: normal;
}

// 3. Medium
@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Medium.otf);
  font-weight: 600;
  font-style: normal;
}

// 4. Bold
@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

// 5. Black
@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Black.otf);
  font-weight: 800;
  font-style: normal;
}

// 6. Ultra
@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Ultra.otf);
  font-weight: 900;
  font-style: italic;
}

// Uniform Webfont
$uniformExtraCondensed: 'uniformExtraCondensed',helvetica,arial,sans-serif;

//------------------------------
// Work Sans
//------------------------------

// @import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
   @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$worksans: 'Work Sans', sans-serif;

//----------------------------------------------
// Variables
//----------------------------------------------

$sans: $worksans;
// $serif: $cardoregular;
$monospace: $robotomono;
$icon: "fontello";
