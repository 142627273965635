/**
 * SOCIAL MENU
 **********************************************/
.nav-social {
  .l-top-bar & {
    // margin-right: 20px;
    float: right;

    h2.block-title {
      display: none;
    }
  }

  ul.menu li {
    @extend %inline-block;

    @extend %list-reset;
  }

  #home,
  #facebook,
  #twitter,
  #google,
  #youtube,
  #search,
  #vimeo,
  #instagram,
  #rss,
  #contact {
    @include size(24px);

    font-size: 0;
    line-height: 0;
    text-align: center;
    color: #000;

    .l-footer-columns & {
      color: #fff;
    }

    &:before {
      @include font-size(16px);

      line-height: 24px;
    }

    &:hover {
      color: $color-accent;
    }
  }

  #home {
    @include icon-before(home);

    &:hover {}
  }

  #facebook {
    @include icon-before(facebook);

    &:hover {
      color: $facebook;
    }
  }

  #twitter {
    @include icon-before(twitter);

    &:hover {
      color: $twitter;
    }
  }

  #google {
    @include icon-before(google);

    &:hover {
      color: $google;
    }
  }

  #youtube {
    @include icon-before(youtube);

    &:hover {
      color: $youtube;
    }
  }

  #vimeo {
    @include icon-before(vimeo);

    &:hover {
      color: $vimeo;
    }
  }

  #instagram {
    @include icon-before(instagram);

    &:hover {
      color: $instagram;
    }
  }

  #search {
    @include icon-before(search);

    &:hover {}
  }

  #rss {
    @include icon-before(rss);

    &:hover {}
  }

  #contact {
    @include icon-before(mail);

    &:hover {
      color: #fff;
    }
  }

  // @media (max-width: $s) {
  //   display: none;
  // }
}
