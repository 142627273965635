.calendar-calendar td .inner div,
.calendar-calendar td .inner div a,
.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview {
  background-color: transparent;
}

.calendar-calendar td .inner div h3 a {
  color: $color-accent;
  &:hover {
    color: #777;
    }
  }

.calendar-calendar .day-view .full td .inner div.calendar div,
.calendar-calendar .day-view .full td .inner div.calendar div a {
  margin: 0;
}

.calendar-calendar td.calendar-agenda-items div.calendar {
  margin: 0;
  padding: 0;
}

// .calendar-calendar {
//   td .inner div {
//     background-color: transparent;
//     a {
//       background-color: transparent;
//     }
//     h3 a {
//     color: $color-accent;
//     &:hover {
//       color: #777;
//       }
//     }
//   }

//   .month-view .full td.single-day div.monthview,
//   .week-view .full td.single-day div.weekview {
//     background-color: transparent;
//   }

//   .day-view .full td {
//     &.single-day div.dayview {
//       background-color: transparent;
//     }

//     .inner div.calendar div {
//       margin: 0;
//       a {
//         margin: 0;
//       }
//     }
//   }

//   td.calendar-agenda-items div.calendar {
//     margin: 0;
//     padding: 0;
//   }
// }
