.page-blog,
.page-comunicados {
  h1.page-title {
    color: $color-accent;
  }

  .views-exposed-widget label {
    color: $color-accent;
  }

  .views-exposed-widget input[type="text"],
  .views-exposed-widget select {
    background: $color-accent;
    color: #000;
    border: 2px solid $color-accent !important;
    border-radius: 0 none;
    width: 50%;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.view-blog {
  .views-row {
    // border-bottom: 1px solid #000;
  }
}

.view-comunicados {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .front & {
      gap: 0;
    }
    gap: 1rem;
    // .not-front {}

    h3.title {
      text-transform: uppercase !important;
    }

    .views-row {
      flex: 1 1 100%;
      padding: 0% 0% 4%;

      &:first-child {
        .not-front {
          h3.title {
            font-size: 18px !important;
          }
        }
        @include breakpoint($m) {
          flex: 1 1 67%;
          max-width: 65%; //revcrfi9
        }

        @include breakpoint($l) {
          flex: 1 1 67%;
          max-width: 65%; //revcrfi9
        }
      }

      @include breakpoint($s) {
        flex: 1 1 50%;
        // max-width: 48%;//revcrfi9
      }

      @include breakpoint($m) {
        flex: 1 1 32%;
        // max-width: 31%;//revcrfi9
      }

      @include breakpoint($l) {
        flex: 1 1 32%;
        // max-width: 31%;//revcrfi9
      }

      .view-mode-teaser,
      .node {
        display: flex;
        flex-wrap: wrap;
        margin: 0px !important;

        .group-left,
        .group-right {
          flex: 1 1 100%;
          padding: 10px 0px 0px;
          margin: 0px !important;

          h3.title {
            text-transform: none;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.1;
            padding: 8px 0;
            border-left: 1px solid #fff;
            padding-left: 10px;
          }

          .field-name-post-date {
            margin: 0 0 0px;
            font-size: 12px;
            // color: #fff;
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
            display: inline-block;
            padding-bottom: 4px;
          }

          .field-name-node-link {
            text-transform: uppercase;
            text-decoration: none;
            margin-top: 3px;

            a {
              font-weight: 700;
              text-decoration: none;
              font-size: 11px;
              border: 1px solid black;
              padding: 6px 10px 4px;
              border-width: 1px 1px 0px 0px;
              margin-top: 4px;

              &:hover {
                background: black;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.b-articulos-front {
  background: #000;
  h2.block-title {
    color: $color-accent;
    margin-top: 0px;
    padding-top: 60px;
    max-width: 90vw;
    margin: auto;
  }
  .item-list ul.pager {
    margin: 0px 0;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }
  .view-content {
    max-width: 90vw;
    margin: auto;
    .views-row {
      @include breakpoint($s) {
        flex: 1 1 50%;
        max-width: 48%;
      }

      @include breakpoint($m) {
        flex: 1 1 32%;
        max-width: 31%;
      }

      @include breakpoint($l) {
        flex: 1 1 25%;
        max-width: 24%;
      }
      &:first-child {
        h3.title {
          font-size: 14px;
        }
        @include breakpoint($s) {
          flex: 1 1 50%;
          max-width: 48%;
        }

        @include breakpoint($m) {
          flex: 1 1 32%;
          max-width: 31%;
        }

        @include breakpoint($l) {
          flex: 1 1 25%;
          max-width: 24%;
        }
      }
      .view-mode-teaser,
      .node {
        .group-left,
        .group-right {
          h3.title {
            border-left: 1px solid #fff;
            a {
              color: #fff;

              &:hover {
                color: $color-accent;
              }
            }
          }

          .field-name-post-date {
            border-bottom: 1px solid #fff;
          }

          .field-name-node-link {
            a {
              // color: #fff;
              border: 1px solid #fff;
              border-width: 1px 1px 0px 0px;

              &:hover {
                background: transparent;
                color: $color-accent;
              }
            }
          }
        }
      }
    }
  }
}
