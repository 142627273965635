// HEADER
//----------------------------------------------

.b-lang-switch {
  float: right;
  clear: right;

  ul {
    text-align: right;

    li {
      @extend %inline-block;
    }
  }

  a:link, a:visited, a:active, li a.active {
    color: #000;

    &:hover {
      color: #fff;
    }

    &.active {
      display: none;
    }
  }
}
