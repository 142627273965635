.views-exposed-widgets {
  display: flex;
  flex-wrap: wrap;
  .dark & {
    color: #fff;
  }
}

.views-exposed-widget {
  padding: 5px 10px;
  flex: 1 1 100%;
  @include breakpoint($s) {
    flex: 1 1 auto;
  }
  label {
    margin-bottom: 3px;
    text-transform: uppercase;
    @include font-size(14px);
    .dark & {
      color: $color-accent;
    }
  }
  select,
  input[type="text"] {
    border-radius: 0px;
    -webkit-appearance: none;
    border: 2px solid #000 !important;
    padding: 3px 5px;
    text-transform: lowercase;
    @include font-size(14px);
    font-weight: 300;
    background: #000;
    color: #fff;
    border-radius: 3px;
    .dark & {
      border: 2px solid transparent !important;
      // background: rgba(255, 255, 255, 0.07); // !
      color: #fff;
    }
  }
  .chosen-container {
    border-radius: 0px;
    -webkit-appearance: none;
    border: 1px solid #000 !important;
    padding: 0px 5px;
    text-transform: lowercase;
    @include font-size(14px);
    font-weight: 300;
    background: #000;
    color: #fff;
    border-radius: 3px;
    .dark & {
      border: 1px solid transparent !important;
      background: rgba(255, 255, 255, 0.07);
      color: #fff;
    }
    a.chosen-single {
      box-shadow: none;
      border-radius: 0px;
      border: 0px solid #000 !important;
      background: rgba(255, 255, 255, 0);
      color: #fff;
      .dark & {
        border: 0px solid transparent !important;
        background: rgba(255, 255, 255, 0);
        color: #fff;
      }
    }
    .chosen-drop {
      border-radius: 0px;
      -webkit-appearance: none;
      border: 1px solid #000 !important;
      padding: 0px 5px;
      text-transform: lowercase;
      @include font-size(14px);
      font-weight: 300;
      background: #000;
      color: #fff;
      .dark & {
        border: 1px solid transparent !important;
        background: rgba(80, 80, 80, 0.95);
        color: #fff;
      }
    }
  }
  .form-item-date-filter-value-date,
  .form-item-field-date-actividad-value-value,
  .form-item-field-date-actividad-value-1-value-date {
    &:before {
      content: "\f073";
      font-family: $icon;
      background: #000;
      color: #fff;
      padding: 4px 10px 5px;
      margin-right: 0px;
      position: relative;
      top: 2px;
      .dark & {
        background: rgba(255, 255, 255, 0.07);
        color: #fff;
      }
    }
  }
  &.views-submit-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    input.form-submit {
      padding: 5px 10px;
      margin-left: 8px;
    }
  }
}

.view-filters {
  border-bottom: 0px solid #000;
  margin: 2% 0% 4%;
  padding: 1%;
  // background: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .dark & {
    border-bottom: 0px solid #fff;
    background: rgba(255, 255, 255, 0.02);
  }
}

.views-exposed-form .views-exposed-widget {
  float: none;
  padding: 5px 0px;
}

.views-widget {
  text-align: left;
  @include breakpoint($m) {
    text-align: left;
  }
  .description {
    display: none;
  }
}

.form-item-field-tax-tipo-actividad-tid,
.form-item-field-tax-tipo-actividad-tid-1 {
  @extend %inline-block;
  &.form-type-radio {
    position: relative;
    margin-right: 20px;
    margin-bottom: 10px;
    label.option {
      font-family: $header-font-family;
      font-weight: 700;
      text-transform: uppercase;
      @include font-size(14px);
      margin-left: 10px;
      cursor: pointer;
      &:after {
        content: "";
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0px;
        left: 4px;
        margin-right: 5px;
        background: transparent;
        border: 3px solid #000;
        opacity: 1;
        border-radius: 100px;
        .dark & {
          border: 3px solid #fff;
        }
      }
      &:hover::after {
        opacity: 1;
      }
    }
    input[type="radio"] {
      visibility: hidden;
      background: red;
      &:checked + label:after {
        opacity: 1;
        background: $color-accent;
      }
    }
  }
}

#edit-date-filter-wrapper {
  .form-item-date-filter-value-date {
    @extend %inline-block;
    input[type="text"] {
      border: 2px solid #000 !important;
      text-align: center;
      .dark & {
        border: 2px solid transparent !important;
      }
    }
  }
}

// DATE PICKER
#ui-datepicker-div {
  border: none;
  background: #000;
  padding: 10px;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 6);
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: none;
  background: #111;
  font-weight: 400;
  color: #fff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: none;
  background: $color-accent;
  font-weight: 400;
  color: #212121;
}

.ui-widget-header {
  border: none;
  background: $color-accent;
  color: #222;
  font-weight: 900;
  border-radius: 0px !important;
}

.ui-datepicker .ui-datepicker-title select {
  float: left;
  font-size: 1em;
  margin: 1px 0;
  background: $color-accent;
  border: none;
  border-radius: 0px !important;
}

.ui-widget-content {
  border: 1px solid #aaa;
  color: #fff;
}

tbody {
  border-top: 1px solid $color-accent;
}

// ----------------
.view-programacion-grid {
  .views-exposed-widgets {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    align-items: end;
    gap: 1em;
    input,
    select,
    .views-exposed-widget {
      width: 100%;
    }
  }
  @include breakpoint($m) {
    .views-exposed-widgets {
      flex-direction: row;
      flex-wrap: nowrap;
    }
    // input,
    // select,
    // .views-exposed-widget {
    //   width: 50%;
    // }
  }
}
