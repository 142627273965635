/**
 * MAIN MENU
 **********************************************/
.l-top-bar {
  display: flex;
  flex-wrap: wrap;

  .nav-main {
    order: 1;
    flex: 2 1;
    @include breakpoint(0px 449px){
      flex: 1 1 100%;
      order: 3;
      padding: 5px 0px;
    }
    @include breakpoint(450px 960px){
      flex: 1 1 10%;
      order: 3;
      padding: 5px 0px;
    }
  }//.nav-main

  .nav-social {
    order: 2;
    flex: 1 1;
    text-align: right;
    ul.menu {
      text-align: right;
    }
    @include breakpoint(0px 449px){
      flex: 1 1 100%;
      order: 1;
      padding: 5px 0px;
      margin-right: 0px;
      ul.menu {
        text-align: center;
        display: flex;
        li {
          flex: 1;
        }
      }
    }
    @include breakpoint(450px 960px){
      flex: 1 1 50%;
      order: 1;
      padding: 5px 0px;
      margin-right: 0px;
      ul.menu {
        text-align: center;
        display: flex;
        li {
          flex: 1;
        }
      }
    }
  }//.nav-social

  .block-search {
    order: 3;
    flex: 1 1;
    @include breakpoint(0px 449px){
      flex: 1 1 100%;
      order: 2;
      padding: 5px 0px;
    }
    @include breakpoint(450px 960px){
      flex: 1 1 40%;
      order: 2;
      padding: 5px 0px;
    }
  }//.block-search
}//.l-top-bar

//----------------------------------------------
//----------------------------------------------

.l-top-bar .nav-main,
.l-navigation .nav-main {
  font-family: $sans;
  font-weight: 300;
  float: left;
  margin-top: 2px;
  h2 {
    @include icon-before(menu);
    display: none;
  }
  @media (min-width: 960px) {
    .close-menu, .mobile-logo {
      display: none !important;
    }
  }

  // First level
  //--------------------
  ul.menu {
    li {
      @extend %inline-block;
      font-family: $header-font-family;
      font-weight: 700;
      border-right: 1px solid #000;
      &:hover {
        ul.menu {
          visibility: visible;
        }
      }
      &.last {
        border-right: none;
      }
      a, span {
        padding: 0 20px;
        color: #000;
        text-transform: uppercase;
        &:hover {
          color: $color-link;
        }
      }
      a {
        &:hover {
          color: #fff;
        }
      }
      // @include breakpoint(1024px) {
      //   &.expanded {
      //     &:hover {
      //       &:before {
      //         content: '';
      //         background: #fff;
      //         height: 7px;
      //         width: 7px;
      //         border-radius: 100%;
      //         position: absolute;
      //         margin: 5px 0px 0px 5px;
      //       }
      //     }
      //   }
      // }
    }//li
  }//ul.menu

  // Second level
  //--------------------
  ul.menu ul.menu {
    position: absolute;
    visibility: hidden;
    text-align: left;
    background: $color-accent;
    border-radius: 4px;
    padding: 15px 10px;
    @media (max-width: 960px) {
      background: transparent;
    }
    li {
      a {
        &:hover {
          color: #fff;
        }
      }
    }//li
    @media (max-width: 1024px) {
      text-align: center;
    }
    li {
      display: block;
      border-right: none;
      margin: 0;
      padding: 5px;

      &:hover {
        color: #fff;
      }
      a, span {
        // line-height: 20px;
        font-weight: 300;
        padding: 0 8px;
        text-transform: lowercase;
      }
    }//li
  }//ul.menu ul.menu

  // MOBILE
  //--------------------------------------------
  @media (max-width: 960px) {
    h2 {
      font-family: $sans;
      display: block;
      margin: 0;
      cursor: pointer;
      text-align: center;
      font-size: 0;
      line-height: 0;
      &:before {
        @include font-size(24px);
        line-height: auto;
        vertical-align: baseline;
      }
    }

    // First level
    //------------------
    ul.menu {
      display: none;
      text-align: center;
      position: fixed;
      background: linear-gradient(to bottom, $color-accent, darken($color-accent, 5%));
      padding: 30px !important;
      z-index: 99;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      overflow-y: scroll;
      border: 10px solid $color-accent;
      li {
        display: block;
        // border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
        border-right: none;
        padding-top: 30px;
        // &:before {
        //   content: '';
        //   background: #fff;
        //   height: 7px;
        //   width: 7px;
        //   border-radius: 100%;
        //   display: inline-block;
        // }
        a {
          font-size: 16px;
          line-height: 32px;
          display: inline-block;
        }
        span {
          font-size: 16px;
        }
        &.close-menu {
          color: #fff;
          @include icon-before(menu);
          @include icon-after(menu);
          a {
            color: #fff;
          }
        }
        &.last {
          border-bottom: none;
        }
      }//li
    }//ul.menu

    // Second level
    //------------------
    ul.menu ul.menu {
      position: relative;
      visibility: visible;
      padding: 0px !important;
      border: none;

      li {
        display: block;
        &:before {
          display: none;
        }
      }//li
    }//ul.menu ul.menu
  }//MOBILE
}// .l-top-bar .nav-main,.l-navigation .nav-main
