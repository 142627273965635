.theme-invitado-honor {
  .l-content {
    .field-name-field-img-principal {
      display: none;
    }

    .view-mode-teaser .field-name-field-img-principal {
      display: block;
    }
  }
}
