// SIZE
//----------------------------------------------
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// FONT SIZE
//----------------------------------------------
@mixin font-size($font-size) {
  font-size: $font-size;
  font-size: $font-size / $base-font-size * 1rem;
}

// IMAGES
//----------------------------------------------

// Logo
//----------------------
@mixin logo($name, $width: false, $height: false, $position: false, $size: false) {
  @extend %replace-image;

  display: block;
  background-image: url(../img/#{$name}.svg);

  .no-svg & {
    background-image: url(../img/#{$name}.png);
  }

  background-repeat: no-repeat;

  @if $size {
    background-size: $size;
  }

  @if $width {
    width: $width;
  }

  @if $height {
    height: $height;
  }

  @if $position {
    background-position: $position;
  }
}

// SVG background image with PNG fallback
//----------------------
@mixin bg-img($name, $size: false) {
  background-image: url(../img/#{$name}.svg);

  @if $size {
    background-size: $size;
  }

  .no-svg & {
    background-image: url(../img/#{$name}.png);
  }
}

// ICONS
//----------------------------------------------

// Icon Before
//----------------------
@mixin icon-before($content) {
  &:before {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;

    vertical-align: baseline !important;
    font: {
      family: $icon;
      style: normal;
      weight: normal;
      variant: normal;
    };
    text: {
      decoration: inherit;
      transform: none;
    };
    speak: none;
    line-height: 1em;
    width: 1em;
  }

  &:before {
    content: icon($content);
  }
}

// Icon After
//----------------------
@mixin icon-after($content) {
  &:after {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;

    vertical-align: baseline !important;
    font: {
      family: $icon;
      style: normal;
      weight: normal;
      variant: normal;
    };
    text: {
      decoration: inherit;
      transform: none;
    };
    speak: none;
    line-height: 1em;
    width: 1em;
  }

  &:after {
    content: icon($content);
  }
}

// BUTTONS
//----------------------------------------------
@mixin btn($color) {
  @extend %btn;

  a {
    background: $color;

    &:hover {
      background: darken($color, 5);
    }
  }
}
