// .v-logos {
//   .view-content {
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
//     text-align: center;
//     .views-row {
//       width: span(4);
//       img {
//         max-width: 100px;
//         margin: 20px;
//       }
//     }
//   }
//   &.v-logos-patrocinadores-1 {
//     .views-row {
//       width: span(6);
//       img {
//         max-width: 120px;
//       }
//     }
//   }
//   &.v-logos-patrocinadores-2 {
//     .views-row {
//       width: span(3);
//     }
//   }
//   &.v-logos-patrocinadores-3 {
//     .views-row {
//       width: span(2);
//     }
//   }
//   &.v-logos-aliados {
//     .views-row {
//       width: span(3);
//       // &:last-child {width: 100%;} // use only if odd number of items
//     }
//   }
// }

.v-logos {
  .view-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
    gap: 2rem;
    margin: 2rem auto 1rem auto;
    .views-row {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.v-logos-crfic10-alianza {
    .view-content {
      grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
      img {
        max-width: 120px;
      }
    }
  }

  &.v-logos-crfic10-patrocina {
    .view-content {
      grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
    }
  }

  &.v-logos-crfic10-colabora {
    .view-content {
      grid-template-columns: repeat(auto-fit, minmax(8%, 1fr));
    }
  }
}
