// SEARCH PAGE / SEARCH BLOCK
//----------------------------------------------
.search-form,
.block-search {
  input[type="text"] {
    padding: 0;
    border-width: 0 0 1px 0 !important;
    border-radius: 0 !important;
  }

  input[type="submit"] {
    font-family: $icon;
    margin: 0;
    padding: 0;
    border: none;
    background: none;

    &:hover {
      background: none;
    }
  }

  .container-inline {
    display: flex;
    align-items: flex-end;

    .form-item-search-block-form {
      flex: 1;
      margin: 0px;
    }
  }
}

// SEARCH PAGE
//----------------------------------------------
#search-form {
  margin: 40px auto 40px 0;
  padding-top: 20px;
  padding-bottom: 20px;

  label {
    display: none;
  }

  input[type="text"] {
    @include font-size(32px);

    height: 40px;
    background: none;
    min-width: 240px;
    color: #000;
    border-color: #000 !important;
    background: transparent;
  }

  input[type="submit"] {
    @include size(40px);

    @include font-size(24px);

    color: #000;

    &:hover {
      color: $color-link;
    }
  }

  ::-webkit-input-placeholder {
    color: #000;
  }

  :-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #000;
  }
}

// SEARCH BLOCK
//----------------------------------------------
.block-search {
  float: right;
  text-align: center;

  input[type="text"] {
    min-width: 95%;
    color: #000;
    border-width: 0 0 1px 0 !important;
    border-color: #000 !important;
    border-radius: 0 !important;
    background: transparent;

    @media (max-width: 520px) {
      // display: none;
    }
  }

  input[type="submit"] {
    margin: 0 0 -30px 0;

    @include size(24px);

    color: #000;

    &:hover {
      color: #fff;
    }
  }

  ::-webkit-input-placeholder {
    color: #000;
  }

  :-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #000;
  }
}

// SEARCH RESULTS
//----------------------------------------------
.page-search {
  h1.page-title {
    display: none;
  }

  .l-main {
    .l-content {
      article.node {
        border-bottom: 1px dashed $color-accent;
        margin-bottom: 60px;
        .content {
          display: flex;
          flex-wrap: wrap;
          fieldset {
            border: none;
          }
          .group-search-left {
            flex: 1 1 33%;
            @media (max-width: 768px)  {
              flex: 1 1 100%;
            }
          }
          .group-search-right {
            flex: 1 1 66%;
            .fieldset-wrapper {
              .field {
                margin-bottom: 8px;
              }
              .field-name-field-tax-pais {
                text-transform: uppercase;
                font-weight: 900;
                @include font-size(12px);
              }
              .field-name-field-tax-edicion-festival {
                color: $color-accent;
                text-transform: uppercase;
                font-weight: 700;
                @include font-size(12px);
              }
              .field-name-field-tax-tipo-articulo {
                text-transform: lowercase;
                font-weight: 700;
                @include font-size(12px);
                font-style: italic; 
              }
              .field-collection-item-field-collection-actividades {
                .content {
                  .field {
                    flex: 1 1 100%;
                  }
                }
              }
            }
            @media (max-width: 768px)  {
              flex: 1 1 100%;
            }
          }
        }
      }
    }
  }
}

.search-result {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-divider;

  strong {
    background: yellow;
  }

  h3.title {
    @include font-size(24px);

    margin: 0;
    font-weight: normal;
  }
}
