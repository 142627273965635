// FOOTER
//----------------------------------------------

// USER
.block-user,
.block-logintoboggan {
  float: right;
}

// FIRMA
.b-firma {
  font-family: $sans;
  text-transform: uppercase;
  text-align: center;
  line-height: 24px;
  font-weight: 300;
}
