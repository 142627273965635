// Global
//----------------------------------------------
* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.l-top-bar,
.l-header,
.l-navigation,
.l-triptych,
.l-above-main,
.l-main,
.l-footer-columns,
.l-footer {
  @extend %container;
}

.l-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.l-content {
  order: 2;
}
.l-sidebar-first {
  order: 1;
}
.l-sidebar-second {
  order: 3;
}

.l-triptych,
.l-footer-columns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
}

.l-triptych-item,
.l-footer-columns-item {
  flex: 1;
}

// Medium
//----------------------------------------------
@include breakpoint($m) {
  .l-content {
    flex-basis: span(8);
    // order: 1;
    .no-sidebars & {
      margin: 0 auto;
    }
  }

  // .l-sidebar-first {
  // padding-top: 240px; //testing
  // }

  .l-triptych,
  .l-footer-columns {
    flex-direction: row;
  }
  // 2 columns
  .l-triptych-first {
    flex-basis: span(4);
  }
  .l-triptych-middle {
    // @include span(last 8);
    flex-basis: span(8);
  }
}

// Large
//----------------------------------------------
@include breakpoint($l) {
  .l-main {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .l-content {
    flex-basis: span(9);
    order: 2;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex-basis: span(3);
  }
}
