.b-retrospectiva-lynne-sachs {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;

  a.more {
    text-align: right;
    text-transform: uppercase;
  }
  // @extend %wide;
  // background-color: #3fbfad;

  .group-left,
  .group-right {
    @include span(full);
  }
  @include breakpoint($m) {
    .group-left,
    .group-right {
      clear: none;
    }
    .group-left {
      @include span(4);
    }

    .group-right {
      @include span(last 8);
    }
  }
}
