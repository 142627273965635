// 2021 Theme Overrides
//--------------------------------------------------------------

// @sass/layout/_l-regions.scss
//------------------------------

// top bar
//--------------
.l-wrapper-top-bar {
  background: #000;
}

// @sass/layout/_l-branding.scss
//------------------------------

// .l-branding {
//   h1.site-name {
//     margin: 0;
//     a {
//       background-size: 100%;
//       text-indent: 1000%;
//       @include logo(logo-festival, 270px, 105px);
//     }
//   }
// }

.branding-global {
  .block-content {
    justify-content: flex-end;
    a,
    &.crfic {
      padding: 0 10px;
      opacity: 1;
      &:hover {
        opacity: 0.8;
      }
      // border-right: 1px solid #fff;
      border: 0 none;
      &:last-child {
        border-right: none;
      }
    }
  }
}

.l-top-bar {
  #crfic {
    background-size: 100%;
    text-indent: 1000%;
    @include logo(logo-festival, 270px, 105px);
  }
}

// @sass/layout/_nav-main.scss
//------------------------------
.l-top-bar .nav-main,
.l-navigation .nav-main {
  h2 {
    color: #fff;
  }
  ul.menu li {
    vertical-align: baseline;
    border: 0 none;
    a,
    span {
      padding: 0 10px;
      color: #fff;
      &:hover {
        color: $color-accent !important;
      }
    }
  }
}

// @sass/layout/_nav-social.scss
//------------------------------
.nav-social {
  #home,
  #facebook,
  #twitter,
  #google,
  #youtube,
  #search,
  #vimeo,
  #instagram,
  #rss,
  #contact {
    color: #fff;
  }
}

// @sass/base/_search.scss
//------------------------------
.block-search {
  input[type="submit"],
  input[type="text"] {
    color: #fff;
    &:hover {
      color: $color-accent;
    }
    border-color: #fff !important;
  }
}

// ---------------------------------------------
// ---------------------------------------------
// TMP
// ---------------------------------------------
// ---------------------------------------------

.l-top-bar {
  display: inherit; //keep
  // background: magenta; //testing
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.branding-global {
  // background: cyan; //testing
  clear: right; //testing
}

.b-presentacion {
  font-size: 1.5rem;
  margin: 4rem 0;
  text-align: justify;
}

.b-articulos-front {
  background: transparent;
  h3.title {
    border: 0 none;
    a {
      color: #000 !important;
    }
  }
}

.b-programacion {
  @include bleed-x(100%);
  background: #fff; //!
  h2.block-title {
    margin-top: inherit;
  }
}
.b-peliculas-premiadas {
  // background: silver;
  @include bleed-x(100%);
  // .b-peliculas-premiadas h2.block-title{
  //   margin: 0;
  // }
  // > .views-exposed-widgets {display: none;}
}

.view-Mosaico-de-Inicio {
  margin: 4rem auto;
  img {
    display: none;
  }
}
