.b-programacion {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), #000);
  padding: 40px;
  h2.block-title {
    color: $color-accent;
    font-family: $header-font-family;
    text-align: center;
    font-weight: 900;
    @include font-size(28px);
    &:after {
      content: "";
      width: 50px;
      height: 8px;
      background: $color-accent;
      display: block;
      margin: 10px auto 20px;
    }
  }
}

.page-programacion {
  .l-wrapper-main {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), #000);
    h1 {
      color: $color-accent;
    }
  }
}

.view-programacion {
  .view-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    h3 {
      text-transform: uppercase;
      flex: 1 1 100%;
      text-align: center;
      margin: 4% 1% 1% 1%;
      padding: 10px 0px;
      font-weight: 900;
      color: #000; // background: $color-accent;
      clear: left;
      text-align: left;
      a {
      }
      p {
        text-align: left;
      }
    }
    .views-row {
      position: relative;
      margin-bottom: 20px;
      color: #fff;
      flex: 1 1 100%;
      background-size: cover;
      background-position: 50%;
      overflow: hidden;
      transition: 0.3s all;
      margin: 4% 0% 16% 0%;
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        z-index: 98;
      }
      @include breakpoint($s) {
        margin: 1% 1% 4% 1%;
        flex: 1 1 48%;
        max-width: 48%;
      }
      @include breakpoint($m) {
        flex: 1 1 31%;
        max-width: 31%;
      }
      @include breakpoint($l) {
        flex: 1 1 23%;
        max-width: 23%;
      }
      a {
        color: $color-accent;
        &:hover {
          color: #fff;
        }
      } // CONTENT THEMING
      .container {
        height: 100%;
        background: rgba(0, 0, 0, 0);
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        .imagen {
          flex: 1 1 100%;
          img {
          }
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: left;
          flex-wrap: wrap;
          padding: 0 10px 10px 10px;
          @include font-size(12px);
          text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
          flex: 1 1 100%;
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.8),
            #000
          );
          .tipo {
            @include font-size(6px);
            text-transform: uppercase;
            padding: 10px 10px 2px 10px;
            border-bottom: 1px solid $color-accent;
            margin: 0px -10px;
            letter-spacing: 0.5px;
            font-weight: 500;
            background: rgba(255, 255, 255, 0.03);
            width: 100% !important;
            text-align: right;
            flex: 1 1 100%;
            &.Formación {
              // background: #d3182b;
            }
            &.Evento {
              // background: #1aa0e0;
            }
          }
          .title,
          .paisano,
          .generoduracion,
          .categoria,
          .sede,
          .fecha {
            flex: 1 1 100%;
          }
          .ano,
          .pais,
          .genero,
          .duracion {
            display: inline-block;
          }
          .paisano,
          .generoduracion {
            a {
              &:last-of-type {
                &:after {
                  content: " | ";
                }
              }
            }
          }
          .title {
            text-transform: uppercase;
            font-weight: 700;
            @include font-size(18px);
            letter-spacing: 1px;
            font-family: $header-font-family;
            background: rgba(255, 255, 255, 0.01);
            margin-left: -10px;
            margin-right: -10px;
            text-align: left;
            padding: 14px 10px;
            a {
              //   color: #fff;
              &:hover {
                // color: $color-accent;
              }
            }
            &:after {
              content: "";
              background: $color-accent;
              height: 4px;
              width: 30px;
              display: block;
            }
          }
          .paisano {
          }
          .generoduracion {
            a {
              @include font-size(12px);
            }
            span {
              &:after {
                content: "'";
              }
            }
          }
          .categoria {
            // display: none;
          }
          .fechasede {
          }
          .sede {
          }
          .fecha {
          }
          .invitacion {
            color: lighten($youtube, 30%);
          }
        }
      }
    }
  }
}
