.field-name-field-eref-producciones {


	.field-items {
		display: flex;
		flex-wrap: wrap;

		.field-item {
			flex: 1 1 100%;
			margin: 1%;
			// height: 275px;

			@include breakpoint($m){
				flex: 1 1 30%;
				max-width: 33%;
			}

			@include breakpoint($l){
				flex: 1 1 24%;
				max-width: 25%;
			}

			.node-produccion {
				height: 100%;
				overflow: hidden;
			}

			.field-name-field-img-principal {
				padding: 0px;
			}

			.field-name-title {
				padding: 10px 10px 4px 10px;
				background-color: #000;
				color: $color-accent;
				border-top: 2px solid $color-accent;

				h3.title {
					@include font-size(15px);
					margin: 0px;
				}

				a {
					color: $color-accent;

					&:hover {
						color: #fff;
					}
				}
			}

			.field-name-field-tax-pais, .field-name-field-date-lanzamiento, .field-name-field-hms-duracion  {
				padding: 0px 10px;
				@include font-size(12px);
				color: #fff;
				background-color: rgba(0,0,0,1);

				a {
					color: #fff;

					&:hover {
						color: $color-accent;
					}
				}
			}

			.field-name-field-tax-pais {
				padding-top: 0px;
			}

			.field-name-field-date-lanzamiento {

			}

			.field-name-field-hms-duracion {
				height: 100%;

				.field-items {

					.field-item {
						margin: 0px;
						height: 100%;
						padding-bottom: 8px;

						span {

							&:after {
								content: "'";
							}
						}

						// &:after {
						//     content: '';
						//     background: $color-accent;
						//     height: 12px;
						//     width: 12px;
						//     border-radius: 100%;
						//     display: block;
						//     margin-top: 15px;
						// }
					}
				}
			}
		}
	}
}