// TOP BAR
//----------------------------------------------
.l-wrapper-top-bar {
  background: $color-accent;
  color: #000;
  .front & {
    // background: transparent;
  }
}

.l-top-bar {
  // padding-top: 5px;
  // padding-bottom: 5px;
  font-size: 80%;
  line-height: 1.4;
  position: relative;
  z-index: 999;
  //
  //
  align-items: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

// HEADER
//----------------------------------------------
.l-wrapper-header {
  // background-color: $color-accent;
  // @include bg-img(bg-textura);
  // background-size: 50%;

  // @include bg-img(slogan-hor);
  // @include bg-img(slogan-2016, contain);

  background-image: linear-gradient(
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.95)
    ),
    url(../img-static/crfic_2016_banner.jpg);
  background-size: cover;
  background-position: center;

  // background-color: #fff;
  // background-repeat: no-repeat;
  // background-position: center top;

  .front & {
    // background-image: url(../img-static/crfic8_header-loop.gif);
    background-image: url(../img-static/crfic8-header-background.jpg);

    // background-image: url(../img-static/pc_crfic2017_d4.jpg);
    // background-image: url(../img-static/crfic_2017_banner.jpg);
    // background-image: url(../img-static/banner-home.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .page-node-1254 &,
  .page-node-1255 & {
    background-image: none;
  }

  .theme-crfic2016 &,
  .page-edicion-1265 & {
    background: linear-gradient(
        rgba(255, 255, 255, 0.95),
        rgba(255, 255, 255, 0.95)
      ),
      url(../img-static/crfic_2016_banner.jpg);
    background-size: cover;
    background-position: center;
  }

  .theme-crfic2015 &,
  .page-edicion-1264 & {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.95),
        rgba(255, 255, 255, 0.95)
      ),
      url(../img-static/banner-home.jpg);
    background-size: cover;
    background-position: center;
  }
}

.l-header {
  position: relative;
}

.l-branding {
  float: left;
  position: relative;
  z-index: 16;

  .front & {
    float: none;
  }
}

.l-header-region {
  float: right;
}

// NAVIGATION
//----------------------------------------------
.l-wrapper-navigation {
}

.l-navigation {
  position: relative;
  z-index: 8;
  overflow: visible;
}

// FEATURED
//----------------------------------------------
.l-wrapper-featured {
}

.l-featured {
  position: relative;

  .field-name-field-img-encabezado {
    text-align: center;
    max-height: 600px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

// MAIN
//----------------------------------------------
.l-above-main,
.l-content,
.l-below-main {
  padding-top: 20px;
  padding-bottom: 20px;
}

.l-wrapper-above-main {
}

.l-above-main {
  .block-views {
    clear: both;
  }
}

.l-wrapper-front_page_featured {
  section.l-front_page_featured {
    .view-blog {
      .views-row {
        border-bottom: none;
      }
    }

    .view-mode-teaser {
      background: $color-accent;
      background-image: url(../img/bg-textura.svg);
      display: flex;
      align-items: center;
      justify-content: left;
      flex-wrap: wrap;
      margin-top: 0px;

      // h3 {
      //   a {
      //     @include font-size(30px);

      //     color: #fff;
      //     line-height: 1.2em;
      //     letter-spacing: 0.5px;

      //     &:hover {
      //       color: #000;

      //     }
      //   }
      // }

      a {
        color: #000;

        &:hover {
          color: #fff;
        }
      }

      .group-left {
        flex: 1 1 100%;
        float: none;
        margin: 0px;

        @include breakpoint(880px) {
          flex: 1 1 50%;
        }
      }

      .group-right {
        flex: 1 1 100%;
        height: 100%;
        float: none;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 20px;

        div {
          flex: 1 1 100%;
        }

        h2.title {
          @include font-size(55px);
          margin-top: 16px;
          margin-bottom: 0px;

          a {
            color: #fff;
            font-weight: 900;

            &:hover {
              color: #000;
            }
          }
        }

        .field-name-field-tax-pais {
          a {
            font-weight: 600;
          }
        }

        @include breakpoint(880px) {
          flex: 1 1 50%;
        }
      }
    }
  }
}

.l-wrapper-main {
}

.l-main {
  // margin-top: 40px;
  // margin-bottom: 40px;
}

.l-content {
  .node-type-produccion & {
    position: relative;
    z-index: 98;
    background: #fff;
    padding: 10px 20px;
    margin-top: -10px;

    @include breakpoint($s) {
      margin-top: -15px;
    }

    @include breakpoint($m) {
      margin-top: -40px;
    }
  }
}

.l-wrapper-below-main {
  background: #000;
}

.l-below-main {
  color: #fff;
}

// TRIPTYCH
//----------------------------------------------
.l-wrapper-triptych {
  background: #000;
}

// .l-triptych-item {
// }

// .l-triptych {
//   display: flex;
//   flex-wrap: wrap;
// }

// .l-triptych-last {
//   flex: 1 1 100%;
//   width: 100%;
//   max-width: 100%;
// }

// FOOTER COLUMNS
//----------------------------------------------
.l-wrapper-footer-columns {
  background: #161616;
}

.l-footer-columns {
}

.l-footer-columns-item {
}

// TRIPTYCH / FOOTER COLUMNS
//----------------------------------------------
.l-triptych-item,
.l-footer-columns {
  padding: 40px 0;
  padding: 40px 0;

  @include font-size(12px);

  line-height: 1.4;
  color: #fff;

  h2.block-title {
    @include font-size(13px);

    font-family: $header-font-family;
    font-weight: 700;
    font-weight: lighter;
    padding-bottom: 10px;
    color: #999;
    border-bottom: 1px solid #999;
  }

  a {
    color: #fff;

    &:hover {
      color: $color-accent;
    }
  }
}

// FOOTER
//----------------------------------------------
.l-wrapper-footer {
  background: #000;
}

.l-footer {
  color: #999;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 80%;
  line-height: 1.4;
}
