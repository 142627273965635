/**
 * FORMS
 **********************************************/

// Input
//----------------------
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
textarea,
.recaptcha_input_area input[type="text"] {
  box-sizing: border-box;
  max-width: 100%;
  padding: 2px 0px 2px 5px;
  border: 1px solid $color-divider !important;
  border-radius: 2px;

  &:focus {
    outline: none;
  }
}

// Submit Buttons
//----------------------
input[type="submit"] {
  @include font-size(16px);

  margin: 0;
  padding: 6px 8px;
  box-shadow: none;
  border: none !important;
  border-radius: 2px;
  transition: all 250ms ease-in-out;
  background: $color-accent;
  font-family: $header-font-family;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    background: darken($color-accent, 10%);
  }
}

// File submit buttons
.form-managed-file {
  input[type="submit"] {
    margin-left: 10px;
  }
}

// Text areas
//----------------------
.resizable-textarea .grippie {
  border: 0;
  background: none;
}

// Captcha
//----------------------
fieldset.captcha {
  padding: 0;
  border: 0;

  img#recaptcha_logo,
  .fieldset-legend {
    display: none;
  }

  .fieldset-description {
    padding: 10px 0;
  }
}

// Views
//----------------------
.views-exposed-widgets {
  margin: 0;
}

.views-exposed-form .views-exposed-widget {
  padding: 0;

  .form-submit {
    margin: 0;
  }
}

// Webforms
//----------------------
.node-type-webform {
  .recaptchatable,
  .recaptchatable #recaptcha_image {
    border: 0 !important;
  }
}

// Required fields
//----------------------
.marker, .form-required {
  // color: red;
}
