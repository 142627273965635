.view-banner-cards {

  .banner-card {
    background: $color-accent;
    display: flex;
    flex-direction: column;
    @include breakpoint($m) {
      flex-direction: row;
    }
  }

  .banner-card_media {
    width: 100%;
    @include breakpoint($m) {
      width: span(7);
    }
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .banner-card_text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    color: #fff;
    a {
      color: #fff;
      &:hover {
        color:#000;
      }
    }

    h3.banner-card_title {
      text-transform: none;
      font-size: 2rem;
    }

  }

}
