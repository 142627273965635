// nav.slick__arrow {
//     button {
//         border-radius: 3px;
//         background: $color-accent;
//         &:focus {
//             background-color: $color-accent;
//         }
//         &:after, &:before {
//             font-size: 2.5rem;
//             color: #fff;
//             opacity: 1;
//         }
//     }
// }

.slick-disabled {
  opacity: 0;
}
