// EXPOSED FILTER - GENERAL VIEW
.view-rutas, .view-apps-rutas  {

    .views-exposed-widgets {
        display: flex;
        flex-wrap: wrap;
    }

    .views-exposed-widget {
        padding: 5px 10px;
        flex: 1 1 100%;

        @include breakpoint($m) {
            flex: 1 1 50%;
        }

        label {
            margin-bottom: 3px;
            text-transform: uppercase;
            @include font-size(14px);
        }

        select, input {
            border-radius: 0px;
            -webkit-appearance: none;
            border: 2px solid #000;
            padding: 3px 5px;
            text-transform: lowercase;
            @include font-size(14px);
            font-weight: 900;
            background: #000;
            color: #fff;
            // width: 100%;
        }

        .form-item-date-filter-value-date { 
            &:before {
                content: '\f073';
                font-family: $icon;
                background: #000;
                color: #fff;
                padding: 6px 10px 7px;
                margin-right: -10px;
            }
        }
    }
}

.view-rutas, .view-apps-rutas {

    .view-filters {
        border-bottom: 1px solid #000;
        margin: 1%;
        padding: 1% 0;
    }

    .views-exposed-form .views-exposed-widget {
        float: none;
        padding: 5px 0px;
    }

    .views-widget {
        text-align: left;

        @include breakpoint($m) {
            text-align: left;
        }

        .description {
            display: none;
        }
    }

    .form-item-field-tax-tipo-actividad-tid {
        @extend %inline-block;

        &.form-type-radio {
          position: relative;
          margin-right: 20px;
          margin-bottom: 10px;

          label.option {
            font-family: $header-font-family;
            font-weight: 700;
            text-transform: uppercase;
            @include font-size(14px);
            margin-left: 10px;
            cursor: pointer;

            &:after {
              content: '';
              width: 20px;
              height: 20px;
              position: absolute;
              top: 0px;
              left: 0px;
              margin-right: 5px;
              background: transparent;
              border: 3px solid #000;
              opacity: 1;
            }
            &:hover::after {
              opacity: 1;
            }
          }
          input[type=radio] {
            visibility: hidden;
            background: red;
            &:checked + label:after {
              opacity: 1;
              background: $color-accent;
            }
          }
        }
    }

    #edit-date-filter-wrapper {
        .form-item-date-filter-value-date {
            @extend %inline-block;

            input[type=text] {
                border: 2px solid #000 !important;
                text-align: center;
            }
        }
    }
}


// VIEW RUTAS
.view-rutas, .view-apps-rutas {


    .view-header {
        z-index: 98;
        background: rgba(255,255,250,0.9);
        padding: 2%;
        left: -100%;
        top: 0%;
        position: fixed;
        overflow: scroll;
        width: 70%;
        height: 100%;
        text-align: center;
        transition: 0.3s all;
        box-shadow: 0 0 8px rgba(0,0,0,0.5);


        &.open {
            left: -0%;
        }

        .togglefilter {
            position: fixed;
            left: 0px;
            top: 100px;
            background: $color-accent;
            color: #000;
            padding: 10px 20px;
            font-weight: 900;
            text-transform: uppercase;
            @include font-size(20px);
            border: none;
            box-shadow: 0 0 8px rgba(0,0,0,0.5);
            transition: 0.3s all;

            &:hover {
                background: #000;
                color: $color-accent;
            }

            &:before {
                content: '\f0c9';
                font-family: $icon;
                margin-right: 5px;
            }
        }

        .closemenu {
            position: absolute;
            right:30px;
            top: 30px;
            background: $color-accent;
            color: #000;
            padding: 10px 20px;
            font-weight: 900;
            text-transform: uppercase;
            @include font-size(20px);
            border: none;
            box-shadow: 0 0 8px rgba(0,0,0,0.5);
            transition: 0.3s all;
            border-radius: 100%;

            &:hover {
                background: #000;
                color: $color-accent;
            }

            &:before {
                // content: '\f0c9';
                // font-family: $icon;
                // margin-right: 5px;
            }
        }


        .view-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 0px;

            ul {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                height: auto !important;
                margin: 10px;
                color: #fff;
                flex: 1 1 100%;
                background-size: cover;
                background: #000;
                text-align: center;
                padding: 5px 10px; 
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;

                @include breakpoint($m) {
                    flex: 1 1 30%;
                }


                a {
                    @include font-size(12px);
                    font-weight: 100;
                    color: #fff;

                    &:hover {
                        color: $color-accent;
                    }

                    &.selected {
                        color: $color-accent;
                        font-weight: 900;
                    }

                    &:before {
                        content: '';
                        background: $color-accent;
                        height: 14px;
                        width: 14px;
                        border-radius: 100%;
                        display: none;
                        margin-right: 5px;
                    }
                }
            } 

            span {
                @include font-size(28px);
                background: #000;
                color: #fff;
                font-weight: 100;
                font-weight: 900;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                margin: 1%;
                padding: 1%;
                border-radius: 3px;

                &:before, &:after {
                    content: '';
                    background: $color-accent;
                    height: 14px;
                    width: 14px;
                    border-radius: 100%;
                    display: inline-block;
                    margin-right: 15px;
                    margin-bottom: 0px;
                }

                &:after {
                    margin-left: 15px;
                    margin-right: 0px;
                }
            }
        }
    }

    .view-content {
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        // flex-wrap: wrap;
        // margin-bottom: 160px;

    //     h3 {
    //         text-transform: uppercase;
    //         flex: 1 1 100%;
    //         text-align: center;
    //         margin: 4% 1% 1% 1%;
    //         padding: 10px 0px;
    //         font-weight: 900;
    //         color: #000;
    //         // background: $color-accent;
    //         clear: left;
    //         text-align: left;

    //         a {

    //         }

    //         p {
    //             text-align: left;
    //         }
    //     }

    ul.isotope-container {
        display: flex;
        flex-wrap: wrap;

        li {
            height: 400px!important;
            flex: 1 1 100%;
            margin: 1%;
            list-style: none;
            position: relative !important;
            margin-bottom: 20px;
            color: #fff;
            background-size: cover;
            background-position: 50%;
            width: 100%;
            display: inline-block;
            float: left !important;
            top: 0px !important;
            left: 0px !important;

            &.isotope-grid-sizer, &.isotope-gutter-sizer {
                height: 0px !important;
            }

            @include breakpoint($m) {
                // margin: 1%;
                flex: 1 1 48%;
                width: 48%;
            }


            a {
                color: $color-accent;

                &:hover {
                    color: #fff;
                }
            }


            // CONTENT THEMING
            .container {
                height: 100%;
                background: rgba(0, 0, 0, 0.0);
                display: flex;
                align-items: flex-end;

                .tipo {
                    @include font-size(6px);

                    // background: #000;
                    text-transform: uppercase;
                    padding: 0 0 0px 0;
                    border-bottom: 1px solid $color-accent;
                    margin-bottom: 2px;
                    letter-spacing: 0.5px;
                    font-weight: 500;

                    &.Formación {
                        background: #d3182b;
                    }

                    &.Evento {
                        background: #1aa0e0;
                    }
                }

                .content {
                    // height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    flex-wrap: wrap;
                    padding: 10px;
                    @include font-size(14px);
                    text-shadow: 0 0 2px rgba(0,0,0,0.4);
                    flex: 100%;
                    background: rgba(0,0,0,0.5);

                    .title, .paisano, .generoduracion, .categoria, .sede, .fecha {
                        flex: 1 1 100%;
                    }

                    .ano, .pais, .genero, .duracion {
                        display: inline-block;
                    }

                    .paisano, .generoduracion {

                        a {

                            &:last-of-type {

                                &:after {
                                    content: " | ";
                                }
                            }
                        }
                    }

                    .title {
                        text-transform: uppercase;
                        font-weight: 100;
                        @include font-size(34px);
                        letter-spacing: 1px;
                        font-family: $header-font-family;


                        &:after {
                            content: '';
                            background: #fff;
                            height: 8px;
                            width: 30px;
                            display: block;
                            // margin-top: 15px;
                        }
                    }

                    .paisano {
                        // margin: 0px auto 40px;
                        // display: none;
                    }

                    .generoduracion {
                        // margin: 40px auto 0px;

                        a {
                            @include font-size(12px);
                        }

                        span {
                            &:after {
                                content: "'";
                            }
                        }
                    }

                    .categoria {
                        display: none;
                    }

                    .fechasede {

                    }

                    .sede {
                        @include font-size(14px);

                        // &:before {
                        //     content: '';
                        //     background: $color-accent;
                        //     height: 12px;
                        //     width: 12px;
                        //     border-radius: 100%;
                        //     display: block;
                        //     margin-bottom: 15px;
                        // }
                    }

                    .fecha {
                        @include font-size(14px);
                    }

                    .invitacion {
                        color: lighten($youtube, 30%);
                    }
                }
            }
    //     }
        }
    }

    // .more-link {
    //     @include span(12 of 12 0.25 inside fluid);
    //     text-align: center;
    //     margin: 80px auto;

    //     a {
        // font-family: $header-font-family;
        // font-weight: 700;
    //         @include font-size(18px);
    //         padding: 5px 20px;
    //         border: 2px solid #000;
    //         border-radius: 0px;
    //         text-transform: uppercase;

    //         &:hover {
    //             background: $color-accent;
    //             border-color: $color-accent;
    //             color: #fff;
    //         }
    //     }
    }
}











